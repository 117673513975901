<template>
    <v-container style="width: 100%; margin-left: 0px; margin-right: 0px;">
        <v-row no-gutters style="width: 125%;">
            <v-col cols="2 mr-1 mt-2" style="width: 20%;">
                <v-card class="pa-2 customer-card-1" style="width: 100%;" outlined tile>
                    <span class="mt-4">{{ current_time }}
                        <p> Realtime insight</p>
                    </span><br>
                    <span style="color: #252c58; font-size:19px; font-weight: 500;">
                        Today:<br>
                        {{ current_date }}
                    </span>
                    <v-card-actions style="width: 100%; justify-content: center;">
                        <a class="ml-2 mt-5 card-button" outlined small :href="`/user-attendances`">
                            View Attendance
                        </a>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="10" class='row'>
                <v-col cols="3 mr-2 mt-2">
                    <v-card class="pa-2 customer-card-2" outlined tile>
                        <v-row style="justify-content: space-between; align-items: center; padding: 4px 16px;">
                            <v-card-title class="text-h5 title" v-text="counts.totalUser"></v-card-title>
                            <v-icon class="icon" rounded>mdi-account-multiple-outline</v-icon>
                        </v-row>
                        <v-card-subtitle class="subtitle" v-text="'Total Employees'"></v-card-subtitle>
                    </v-card>
                </v-col>
                <v-col cols="3 mr-2 mt-2">
                    <v-card class="pa-2 customer-card-2" outlined tile>
                        <v-row style="justify-content: space-between; align-items: center; padding: 4px 16px;">
                            <v-card-title class="text-h5 title" v-text="counts.totaluser_present_today"></v-card-title>
                            <v-icon class="icon" rounded>mdi-clock-outline</v-icon>
                        </v-row>
                        <v-card-subtitle class="subtitle" v-text="'Total Present Today'"></v-card-subtitle>
                    </v-card>
                </v-col>
                <v-col cols="3 mr-2 mt-2">
                    <v-card class="pa-2 customer-card-2" outlined tile>
                        <v-row style="justify-content: space-between; align-items: center; padding: 4px 16px;">
                            <v-card-title class="text-h5 title" v-text="counts.totaluser_absent_today"></v-card-title>
                            <v-icon class="icon" rounded>mdi-weather-cloudy-clock</v-icon>
                        </v-row>
                        <v-card-subtitle class="subtitle" v-text="'Total Absent Today'"></v-card-subtitle>
                    </v-card>
                </v-col>
                <v-col cols="3 mr-2 mt-2">
                    <v-card class="pa-2 customer-card-2" outlined tile>
                        <v-row style="justify-content: space-between; align-items: center; padding: 4px 16px;">
                            <v-card-title class="text-h5 title" v-text="counts.totaluser_onleave_today"></v-card-title>
                            <v-icon class="icon" rounded>mdi-car</v-icon>
                        </v-row>
                        <v-card-subtitle class="subtitle" v-text="'Total OnLeave Today'"></v-card-subtitle>
                    </v-card>
                </v-col>
                <v-col cols="3 mr-2 mt-2">
                    <v-card class="pa-2 customer-card-2" outlined tile>
                        <v-row style="justify-content: space-between; align-items: center; padding: 4px 16px;">
                            <v-card-title class="text-h5 title" v-text="counts.totaluser_leave_approved"></v-card-title>
                            <v-icon class="icon" rounded>mdi-account-check-outline</v-icon>
                        </v-row>
                        <v-card-subtitle class="subtitle" v-text="'Total Leave Approved'"></v-card-subtitle>
                    </v-card>
                </v-col>
                <v-col cols="3 mr-2 mt-2">
                    <v-card class="pa-2 customer-card-2" outlined tile>
                        <v-row style="justify-content: space-between; align-items: center; padding: 4px 16px;">
                            <v-card-title class="text-h5 title" v-text="counts.totaluser_leave_decline"></v-card-title>
                            <v-icon class="icon" rounded>mdi-account-cancel-outline</v-icon>
                        </v-row>
                        <v-card-subtitle class="subtitle" v-text="'Total Leave Decline'"></v-card-subtitle>
                    </v-card>
                </v-col>
            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
import axios from "axios";

export default {
    name: "SACounts",
    data() {
        return {
            counts: {
            },
            roleId: "",
            current_date: '',
            current_time: '',
            current_month: new Date()
        };
    },
    mounted() {
        this.getData();
        setInterval(() => {
            this.getCurrentDate();
        }, 1000);
        this.roleId = this.user.roles[0].id;
        console.log(this.current_month)
    },
    methods: {
        async getData() {
            try {
                let query = this.roleId = this.roleId ? this.roleId : "";
                let response = await axios.get(`/admin_dashboard?${query}`);
                console.log(response);
                this.counts = response.data;
                console.log(this.counts);
            } catch (error) {
                console.error("Error fetching data:", error);
            }

        },
        getCurrentDate() {
            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const monthNames = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];
            const monthIndex = today.getMonth();
            const year = today.getFullYear();
            const hours = String(today.getHours()).padStart(2, '0');
            const minutes = String(today.getMinutes()).padStart(2, '0');
            const seconds = String(today.getSeconds()).padStart(2, '0');
            const period = hours >= 12 ? 'PM' : 'AM';
            const formattedDate = `${this.getOrdinalSuffix(day)} ${monthNames[monthIndex]} ${year}`;
            const formattedTime = `${hours}:${minutes}:${seconds} ${period}`;
            this.current_date = formattedDate;
            this.current_time = formattedTime;
        },

        getOrdinalSuffix(number) {
            if (number >= 11 && number <= 13) {
                return `${number}th`;
            }
            const suffixes = ["th", "st", "nd", "rd"];
            const v = number % 10;
            return `${number}${suffixes[v] || suffixes[0]}`;
        },

    },
};
</script>
<style scoped>
.customer-card-1 {
    width: 88%;
    height: 270px;
    border-radius: 11px !important;
    display: flex;
    align-items: start;
    flex-direction: column;
}

.customer-card-1 span {
    color: #9295ab;
    font-size: 23px;
}

.customer-card-1 span p {
    font-size: 12px;
    text-align: start;
}

.card-button {
    padding: 7px 15px !important;
    background: #3354f4;
    color: white;
    border-radius: 10px;
    font-size: 14px;
    text-decoration: none;
}

.customer-card-2 .title {
    color: #252c58;
    font-size: 32px !important;
    font-weight: 500;
}

.customer-card-2 .subtitle {
    color: #4a4f74;
}

.customer-card-2 {
    border-radius: 11px !important;
}

.customer-card-2 .icon {
    background: #f2f4fa;
    border-radius: 50px;
    width: 50px;
    height: 49px;
    color: #0b4bff;

}
</style>
  