<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" class="mt-4 ml-4">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'PJPs Reports'"></span>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData"
                    @change="page = 1"
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="employee"
                    v-model="user_id"
                    :items="employees"
                    label="Employee"
                    @input="getData"
                    prepend-icon="mdi-account"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="3">
                  <v-menu
                    v-model="month_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.month_year"
                        label="Year - Month"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="(filter.month_year = null), getData()"
                        :error-messages="errors.month"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.month_year"
                      type="month"
                      @change="(month_menu = false), getData()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="pjps.length != 0"
              ><v-spacer></v-spacer
              ><v-btn
                class="primary mx-4"
                v-if="ExportPjps.length == 0"
                @click="addToExcelData()"
                ><span v-if="isExcelloading"> Generating Excel... </span>
                <span v-else>Generate Excel</span></v-btn
              >
              <v-btn v-else class="success mx-4"
                ><download-excel
                  :title="title"
                  :data="ExportPjps"
                  :fields="excel_fields"
                  worksheet="Pjps"
                  name="Pjps.xls"
                  >Download Excel
                </download-excel></v-btn
              ></v-card-title
            >
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Employee Name</th>
                    <th class="text-left">Date</th>
                    <th class="text-left">Location</th>
                    <th class="text-left">Mark in Time</th>
                    <th class="text-left">Mark out Time</th>
                    <th class="text-left">Allowance Type</th>
                    <th class="text-left">Description</th>
                    <th class="text-left">Reason for not visit</th>
                    <th class="text-left">Supervisor</th>
                  </tr>
                </thead>
                <tbody v-if="pjps.length != 0">
                  <tr v-for="(pjp, i) in pjps" :key="`pjp${i}`">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="pjp.is_visited == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="green"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Visited</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="pjp.is_visited == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="red"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Not Visited</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td>{{ pjp.user.name || "" }}</td>
                    <td>{{ pjp.date || "" }}</td>
                    <td>{{ pjp.location || "" }}</td>
                    <td>{{ pjp.markin_time || "" }}</td>
                    <td>{{ pjp.markout_time || "" }}</td>
                    <td>{{ pjp.allowance_type?.code || "" }}</td>
                    <td>{{ pjp.description || "" }}</td>
                    <td>{{ pjp.reason_for_not_visit || "" }}</td>
                    <td>{{ pjp.user.supervisor.name || "" }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "pjpsPage",
  data() {
    return {
      filter: {
        month_year: "",
        month: "",
        year: "",
      },
      isVisited: "",
      supervisor_id: "",
      user_id: "",
      date_filter: "",
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      statuses: [
        {
          id: 0,
          text: "Select Visit Status",
          value: "",
        },
        { id: "VISITED", text: "VISITED", value: "1" },
        { id: "NOT VISITED", text: "NOT VISITED", value: "0" },
      ],

      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      allowanceTypes: [
        {
          id: 0,
          text: "Select Allowance Type",
          value: "",
        },
      ],
      date_menu: false,
      month_menu: false,
      isLoading: false,
      isExcelloading: false,
      ExportPjps: [],
      pjps: [],
      Export_pjps: [],
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Date: "date",
        Location: "location",
        "Mark In Time": "mark_in_time",
        "Mark Out Time": "mark_out_time",
        "Allowance Type": "allowance_type",
        Description: "description",
        "Reason for Not Visiting": "reason_for_not_visiting",
        Supervisor: "supervisor",
        "Is Visited": "is_visited",
        "Travel Type": "travel_type",
        "Expense Type": "expenes_type",
        From: "from",
        To: "to",
        Amount: "amount",
      },
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`pjps/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.name,
          value: employee.id,
        });
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        this.supervisors.push({
          id: supervisor.id,
          text: supervisor.name,
          value: supervisor.id,
        });
      });
      // Session Types
      masters.allowanceTypes.forEach((allowanceType) => {
        this.allowanceTypes.push({
          id: allowanceType.id,
          text: allowanceType.code,
          value: allowanceType.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.isVisited = this.isVisited ? this.isVisited : "";
      this.role_id = this.role_id ? this.role_id : "";
      let pjps = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&isVisited=" +
        this.isVisited +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&userId=" +
        this.user_id +
        "&date=" +
        this.date_filter +
        "&supervisorId=" +
        this.supervisor_id;
      pjps = await axios.get(`pjps?${query}`);
      this.pjps = pjps.data.data;
      this.count = pjps.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },

    async addToExcelData() {
      this.isExcelLoading = true;
      this.title = "Company Lists";
      let Export_pjps = [];
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&isVisited=" +
        this.isVisited +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&userId=" +
        this.user_id +
        "&date=" +
        this.date_filter +
        "&supervisorId=" +
        this.supervisor_id;
      Export_pjps = await axios.get(`pjps?${query}`);
      this.Export_pjps = Export_pjps.data.data;

      this.Export_pjps.forEach((pjp) => {
        if (pjp.pjp_expenses.length != 0) {
          pjp.pjp_expenses.forEach((pjpExpense, r) => {
            let single_row = {};
            if (r == 0) {
              single_row = {
                sr_no: pjp.sr_no,
                name: pjp.user ? pjp.user.name.toUpperCase() : "",
                date: pjp.date ? pjp.date : "",
                location: pjp.location ? pjp.location : "",
                supervisor: pjp.user.supervisor
                  ? pjp.user.supervisor.name.toUpperCase()
                  : "",
                is_visited: pjp.is_visited == 1 ? "YES" : "NO",
                travel_type: pjpExpense.travel_mode.description,
                expenes_type: pjpExpense.expense_type.description,
                from: pjpExpense.from,
                to: pjpExpense.to,
                amount: pjpExpense.amount,
              };
            } else {
              single_row = {
                travel_type: pjpExpense.travel_mode.description,
                expenes_type: pjpExpense.expense_type.description,
                from: pjpExpense.from,
                to: pjpExpense.to,
                amount: pjpExpense.amount,
              };
            }
            this.ExportPjps.push(single_row);
          });
        } else {
          this.ExportPjps.push({
            sr_no: pjp.sr_no,
            name: pjp.user ? pjp.user.name.toUpperCase() : "",
            date: pjp.date ? pjp.date : "",
            location: pjp.location ? pjp.location : "",
            supervisor: pjp.user.supervisor
              ? pjp.user.supervisor.name.toUpperCase()
              : "",
            is_visited: pjp.is_visited == 1 ? "YES" : "NO",
          });
        }

        // this.ExportPjps.push({
        //   sr_no: user.sr_no,
        //   name: user.name ? user.name.toUpperCase() : "",
        //   email: user.email ? user.email.toUpperCase() : "",
        //   phone: user.phone ? user.phone : "",

        //   supervisor: user.supervisor_id
        //     ? user.supervisor.name.toUpperCase()
        //     : "",
        //   status: user.isVisited == 1 ? "YES" : "NO",
        // });
      });
      this.isExcelLoading = false;
    },
    getMonthYear() {
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
    },
  },
};
</script>
