<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ml-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit Leave
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete disabled ref="employee" v-model="form.user_id" :items="employees" label="Employee"
                prepend-icon="mdi-account" :error-messages="errors.user_id"
                @change="getUser(form.user_id)"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete disabled ref="supervisor" v-model="form.supervisor_id" :items="supervisors"
                label="Supervisor" prepend-icon="mdi-account" :error-messages="errors.supervisor_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-menu v-model="leave_from_date_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field disabled v-model="form.leave_from_date" label="Leave from date"
                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable
                    @click:clear="form.leave_from_date = null" :error-messages="errors.leave_from_date"></v-text-field>
                </template>
                <v-date-picker v-model="form.leave_from_date" @change="leave_from_date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu v-model="leave_to_date_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field disabled v-model="form.leave_to_date" label="Leave to date" prepend-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on" clearable @click:clear="form.leave_to_date = null"
                    :error-messages="errors.leave_to_date"></v-text-field>
                </template>
                <v-date-picker v-model="form.leave_to_date" @change="leave_to_date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-autocomplete disabled ref="leave type" v-model="form.leave_type_id" :items="leaveTypes"
                label="Leave Type" prepend-icon="mdi-account" :error-messages="errors.leave_type_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field disabled v-model="form.remarks" outlined label="Remark"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field readonly v-model="form.attendance_type" outlined label="Attendance Type"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4" v-if="form.role_id != '4' && form.role_id != '2'">
              <v-switch v-model="form.is_approved" inset
                :label="`Is Approved: ${form.is_approved == 1 ? 'YES' : 'NO'}`"></v-switch>
            </v-col>
            <v-col sm="4" v-if="form.role_id != '4' &&
              form.role_id != '2' &&
              form.is_approved == 0
              ">
              <v-text-field v-model="form.decline_reason" outlined label="Decline reason*"
                prepend-inner-icon="mdi-card-text-outline" :error-messages="error_msg"></v-text-field>
            </v-col>
            <!-- <v-col sm="4">
              <v-text-field
                readonly
                v-model="form.remarks"
                outlined
                label="Reason"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col> -->
            <v-col sm="4">
              <v-text-field readonly v-model="form.note" outlined label="Note"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4" v-if="form.attachment == null">
              <input type="file" id="file" name="file" ref="file" accept="image/*" multiple />
            </v-col>
          </v-row>
          <br />
          <br />
          <v-row v-if="form.leave_flag == false">
            <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>

            <v-btn v-else color="primary" dark @click="save">
              Update User Leave
            </v-btn>
          </v-row>
          <br />
        </v-card-text>
        <v-card-text class="white--text red" style="font-weight: bolder; font-size: large">
          Note: {{ note }}
          <!-- <span v-if="selectedLeaveType == 'PAID LEAVE'"
            >
          </span>
          <span v-if="selectedLeaveType == 'CASUAL LEAVE'"
            >Note: {{ clnote }}
          </span> -->
          <br />
          Pending PL Count : {{ checkUser.pending_pl_leave_count }}
          <br />
          <!-- Pending CL Count : {{ checkUser.pending_cl_leave_count }} -->
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "UserLeaveIdPage",
  data() {
    return {
      form: {
        leave_from_date: "",
        leave_to_date: "",
        attendance_type: "Leave",
        is_approved: false,
      },
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      leaveTypes: [
        {
          id: 0,
          text: "Select Leave Type",
          value: "",
        },
      ],
      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      isLoading: false,
      leave_from_date_menu: false,
      leave_to_date_menu: false,
      checkUser: {},
      note: "",
      error_msg: "",
      // clnote: "",
    };
  },
  computed: {
    checkLeaveDays: function () {
      const startDate = moment(this.form.leave_from_date);
      const endDate = moment(this.form.leave_to_date);
      const daysDifference = endDate.diff(startDate, "days") + 1;
      return daysDifference;
    },
    selectedLeaveType() {
      const selectedId = this.form.leave_type_id;
      const selectedItem = this.leaveTypes.find(
        (item) => item.id === selectedId
      );
      return selectedItem ? selectedItem.text : ""; // Get the name of the selected item
    },
  },
  mounted() {
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.form.supervisor_id = this.user.id;
    }
    this.getData();
    this.getUser(this.form);
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`user_attendances/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (employee.supervisor_id == this.user.id) {
            this.employees.push({
              id: employee.id,
              text: employee.name,
              value: employee.id,
            });
          }
        } else {
          this.employees.push({
            id: employee.id,
            text: employee.name,
            value: employee.id,
          });
        }
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (supervisor.id == this.user.id) {
            this.supervisors.push({
              id: supervisor.id,
              text: supervisor.name,
              value: supervisor.id,
            });
          }
        } else {
          this.supervisors.push({
            id: supervisor.id,
            text: supervisor.name,
            value: supervisor.id,
          });
        }
      });
      // Leave Types
      masters.leaveTypes.forEach((leaveType) => {
        this.leaveTypes.push({
          id: leaveType.id,
          text: leaveType.code,
          value: leaveType.id,
        });
      });
    },
    async getUser(userId) {
      console.log(userId);
      try {
        this.isLoading = true;
        let checkUser = await axios.get(`users/${userId}`);
        this.checkUser = checkUser.data.data;
        this.isLoading = false;
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    async getData() {
      this.isLoading = true;
      if (this.form.is_approved == false) {
        // let error_msg = '';
        this.error_msg = 'The decline reason is required.'
      }
      let form = await axios.get(
        `/user_attendances/${this.$route.params.userLeaveId}`
      );
      this.form = form.data.data;
      this.getUser(this.form.user_id);
      // console.log();
      this.isLoading = false;
    },
    async save() {
      console.log('leave');
      console.log(this.checkLeaveDays);
      this.isLoading = true;
      try {
        if (
          this.selectedLeaveType == "PAID LEAVE" &&
          this.checkUser.total_leave_of_month >= this.checkLeaveDays
        ) {
          await axios.patch(
            `/user_attendances/${this.$route.params.userLeaveId}`,
            this.form
          );
          this.handleFileUpload();
          this.isLoading = false;
          this.$router.push("/user-leaves");
        }
        // else {
        //   this.plnote = "No paid leave is pending.";
        // }
        else if (
          this.selectedLeaveType == "CASUAL LEAVE" &&
          this.checkUser.cl_leave_count >= this.checkLeaveDays
        ) {
          await axios.patch(
            `/user_attendances/${this.$route.params.userLeaveId}`,
            this.form
          );
          this.handleFileUpload();
          this.isLoading = false;
          this.$router.push("/user-leaves");
        } else if (
          this.selectedLeaveType != "CASUAL LEAVE" &&
          this.selectedLeaveType != "PAID LEAVE"
        ) {
          await axios.patch(
            `/user_attendances/${this.$route.params.userLeaveId}`,
            this.form
          );
          this.isLoading = false;
          this.$router.push("/user-leaves");
        } else {
          this.isLoading = false;
          this.note =
            "Can't apply leave for more than " + this.checkUser.total_leave_of_month;
          this.isLoading = false;
        }


        console.log(this.note);

        // console.log(this.form);
        // await axios.patch(
        //   `/user_attendances/${this.$route.params.userLeaveId}`,
        //   this.form
        // );
        // this.isLoading = false;
        // this.$router.push("/user-leaves");
      } catch (e) {
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      console.log(this.form.id);
      let attachment = this.$refs.file.files[0];
      console.log(attachment);
      const user_attendances_id = this.form.id;
      let formData = new FormData();
      formData.append("id", user_attendances_id);
      formData.append("attachment", attachment);
      await axios
        .post("upload_user_leave_attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  },
};
</script>
