<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4">
          <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add a PJP
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete ref="employee" v-model="form.user_id" :items="employees" label="Employee"
                prepend-icon="mdi-account" :error-messages="errors.user_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="employee" v-model="form.allowance_type_id" :items="allowanceTypes"
                label="Allowance Type" prepend-icon="mdi-account"
                :error-messages="errors.allowance_type_id"></v-autocomplete>
                <span class="red--text">{{AllowanceLimit}}</span>
            </v-col>
            <v-col sm="4">
              <label for="">Allowance Type Image</label> <br>
              <input type="file" id="file" name="file" ref="file" accept="image/*" multiple />
            </v-col>
            <v-col sm="4">
              <v-menu v-model="date_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.date" label="Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                    v-on="on" clearable @click:clear="form.date = null" :error-messages="errors.date"></v-text-field>
                </template>
                <v-date-picker v-model="form.date" @change="date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu ref="Mark in time" v-model="login_time_menu" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.markin_time" label="Mark in time" prepend-icon="mdi-clock-time-nine-outline"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="login_time_menu" v-model="form.markin_time" full-width
                  @click:minute="$refs.menu.save(form.markin_time)"></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu ref="Mark out time" v-model="logout_time_menu" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.markout_time" label="Mark out time"
                    prepend-icon="mdi-clock-time-nine-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="logout_time_menu" v-model="form.markout_time" full-width
                  @click:minute="$refs.menu.save(time)"></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.location" outlined :label="label" prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.location"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.latitude" outlined label="Latitude" prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.latitude"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.longitude" outlined label="Longitude" prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.longitude"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.description" outlined label="Description"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch v-model="form.is_visited" inset
                :label="`Is Visited: ${form.is_visited == 1 ? 'YES' : 'NO'}`"></v-switch>
            </v-col>
            <v-col sm="4" v-if="form.is_visited == 0">
              <v-text-field v-model="form.reason_for_not_visit" outlined label="Reason for not visit"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>

          <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save"> Save PJP </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserCreatePage",
  data() {
    return {
      form: {
        is_visited: false,
        markin_time: "",
        markout_time: "",
      },
      companyId: "",
      AllowanceLimit: "",
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      allowanceTypes: [
        {
          id: 0,
          text: "Select Allowance Type",
          value: "",
        },
      ],
      isLoading: false,
      date_menu: false,
      login_time_menu: false,
      logout_time_menu: false,
    };
  },
  mounted() {
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.form.supervisor_id = this.user.id;
    }
    this.getMasters();
    this.companyId = this.user.companies[0].id;
    console.log(this.companyId);
  },
  computed: {
    label() {
      if (this.companyId == 9 || this.companyId == 1) {
        return "Outlet Name";
      } else if (this.companyId == 10 || this.companyId == 1) {
        return "Location";
      } else {
        return ""; // Return an empty string or a default label for other cases
      }
    },
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`pjps/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (employee.supervisor_id == this.user.id) {
            this.employees.push({
              id: employee.id,
              text: employee.name,
              value: employee.id,
            });
          }
        } else {
          this.employees.push({
            id: employee.id,
            text: employee.name,
            value: employee.id,
          });
        }
      });
      masters.allowanceTypes.forEach((Allowance) => {
        this.allowanceTypes.push({
          id: Allowance.id,
          text: Allowance.description,
          value: Allowance.id,
        });
      })
    },
    async save() {
      try {
        this.isLoading = true;
        let pjps = await axios.post("/pjps", this.form);
        console.log(pjps);
        this.pjps = pjps.data.data;
        console.log();
        if(pjps.data.message != null){
          this.AllowanceLimit = pjps.data.message;
        }
        await this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/pjps");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      let allowance_type_image_path = this.$refs.file.files[0];
      const pjp_id = this.pjps.id;
      let formData = new FormData();
      formData.append("pjp_id", pjp_id);
      formData.append("allowance_type_image_path", allowance_type_image_path);
      await axios
        .post("upload_allowance_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  },
};
</script>
