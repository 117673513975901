<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ml-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add a PJP Reference
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="6">
              <v-autocomplete outlined ref="employee" v-model="form.user_id" :items="employees" label="Supervisor"
                prepend-inner-icon="mdi-account"></v-autocomplete>
            </v-col>
            <v-col sm="6">
              <v-text-field v-model="form.location" outlined label="location"
                prepend-inner-icon="mdi-arrow-left-bold"></v-text-field>
            </v-col>
            <v-col sm="6">
              <v-autocomplete outlined ref="Day" v-model="form.day" :items="daysItem" label="Day"
                prepend-inner-icon="mdi-account"></v-autocomplete>
            </v-col>
            <v-col sm="6">
              <v-autocomplete outlined ref="Week" v-model="form.week" :items="weeksItem" label="Week"
                prepend-inner-icon="mdi-account"></v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>

          <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save">
            Save PJP Reference
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserCreatePage",
  data() {
    return {
      form: {
        is_approved: false,
      },
      daysItem: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      weeksItem: [
        "ODD",
        "EVEN",
      ],
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`pjp_references/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.name,
          value: employee.id,
        });
      });
    },
    async getData() {
      let response = await axios.get(`/pjp_references/${this.$route.params.pjpReferenceId}`);
      this.form = response.data.data
    },
    async save() {
      try {
        this.isLoading = true;
        console.log(this.form);
        await axios.patch(`/pjp_references/${this.$route.params.pjpReferenceId}`, this.form);
        this.isLoading = false;
        this.$router.push(`/pjp-references`);
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
  },
};
</script>
