<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ml-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit User
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.name" outlined label="User Name *" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.employee_code" outlined label="Employee Code *" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.employee_code"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.email" outlined label="Email *" prepend-inner-icon="mdi-email"
                :error-messages="errors.email"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.phone" outlined label="Phone *" prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="role" v-model="form.role_id" :items="roles" label="Roles *" prepend-icon="mdi-account"
                :error-messages="errors.role_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="supervisor" v-model="form.supervisor_id" :items="supervisors" label="Supervisor"
                prepend-icon="mdi-account" :error-messages="errors.supervisor_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="designation" v-model="form.designation_id" :items="designations" label="Designation *"
                prepend-icon="mdi-account" :error-messages="errors.designation_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="bloodgroup" v-model="form.blood_group_id" :items="bloodgroups" label="Blood Group *"
                prepend-icon="mdi-account" :error-messages="errors.blood_group_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.branch" outlined label="Branch" prepend-inner-icon="mdi-bank"></v-text-field>
            </v-col>

            <v-col sm="4">
              <v-text-field v-model="form.pf_no" outlined label="PF No" prepend-inner-icon="mdi-buffet"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.esi_no" outlined label="Esi No" prepend-inner-icon="mdi-buffet"></v-text-field>
            </v-col>

            <v-col sm="4">
              <v-text-field v-model="form.uan_no" outlined label="UAN No" prepend-inner-icon="mdi-buffet"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.emergency_2_name" outlined label="Emergency Contact Name"
                prepend-inner-icon="mdi-account"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.emergency_2_contact" outlined label="Emergency Contact"
                prepend-inner-icon="mdi-phone"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.emergency_2_relationship" outlined label="Emergency Contact Reletion"
                prepend-inner-icon="mdi-account-multiple"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.emergency_2_name_2" outlined label="Emergency Contact Name 2"
                prepend-inner-icon="mdi-account"></v-text-field>
            </v-col>

            <v-col sm="4">
              <v-text-field v-model="form.emergency_2_contact_2" outlined label="Emergency Contact 2"
                prepend-inner-icon="mdi-phone"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.emergency_2_relationship_2" outlined label="Emergency Contact Relation 2"
                prepend-inner-icon="mdi-account-multiple"></v-text-field>
            </v-col>

            <v-col sm="4">
              <v-text-field v-model="form.pl_leave_count" outlined label="PL"
                prepend-inner-icon="mdi-city-variant-outline"></v-text-field>
            </v-col>
            <!-- <v-col sm="4">
              <v-text-field
                v-model="form.cl_leave_count"
                outlined
                label="CL"
                prepend-inner-icon="mdi-city-variant-outline"
              ></v-text-field>
            </v-col> -->
            <v-col sm="4">
              <v-menu v-model="doj_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.doj" label="Date Of Joining" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" clearable @click:clear="doj = null"></v-text-field>
                </template>
                <v-date-picker :id="`doj`" v-model="form.doj" @change="doj_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-text-field v-model="form.geolocation_lat" outlined label="Lat"
                prepend-inner-icon="mdi-city-variant"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="form.geolocation_lng" outlined label="Lng"
                prepend-inner-icon="mdi-city-variant"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.imei_no" outlined label="IMEI No"
                prepend-inner-icon="mdi-cellphone-settings"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch v-model="form.active" inset :label="`Is Active: ${form.active == 1 ? 'YES' : 'NO'}`"></v-switch>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="pjpItem" v-model="form.pjp_type" :items="pjpTypeItems" label="Pjp Type *"
                prepend-icon="mdi-account" :error-messages="errors.blood_group_id"></v-autocomplete>
            </v-col>
          </v-row>

          <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save"> Update User </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserEditPage",
  data() {
    return {
      form: {},
      doj_menu: false,
      companyId: "",
      roles: [
        {
          id: 0,
          text: "Select Role",
          value: "",
        },
      ],
      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      bloodgroups: [{ id: 0, text: "Select Blood Group", value: "" }],
      pjpTypeItems: ["Weekly", "Bi-Weekly"],
      designations: [
        {
          id: 0,
          text: "Select Designation",
          value: "",
        },
      ],
      isLoading: false,
    };
  },
  mounted() {
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.form.supervisor_id = this.user.id;
    }
    this.getData();
    this.getMasters();
    this.companyId = this.user.companies[0].id;
    console.log(this.companyId);
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`users/masters`);
      masters = masters.data;
      // Roles
      masters.roles.forEach((role) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (role.name == "USER") {
            this.roles.push({
              id: role.id,
              text: role.name,
              value: role.id,
            });
          }
        } else {
          this.roles.push({
            id: role.id,
            text: role.name,
            value: role.id,
          });
        }
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (supervisor.id == this.user.id) {
            this.supervisors.push({
              id: supervisor.id,
              text: supervisor.name,
              value: supervisor.id,
            });
          }
        } else {
          this.supervisors.push({
            id: supervisor.id,
            text: supervisor.name,
            value: supervisor.id,
          });
        }
      });
      masters.designations.forEach((designation) => {
        this.designations.push({
          id: designation.id,
          text: designation.description,
          value: designation.id,
        });
      });
      //Blood Group
      masters.bloodgroups.forEach((bloodgroup) => {
        this.bloodgroups.push({
          id: bloodgroup.id,
          text: bloodgroup.description,
          value: bloodgroup.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/users/${this.$route.params.userId}`);
      this.form = form.data.data;
      console.log(this.form);
      this.form.roles.length > 0
        ? (this.form.role_id = this.form.roles[0].id)
        : (this.form.role_id = "");
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.patch(`/users/${this.$route.params.userId}`, this.form);
        this.isLoading = false;
        this.$router.push("/users");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
