<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="4">
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData"
                    @change="page = 1"
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-menu
                    v-model="month_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.month_year"
                        label="Year - Month"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="filter.month_year = null"
                        :error-messages="errors.month"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.month_year"
                      type="month"
                      @change="(month_menu = false), getData()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- / Filters -->
      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-progress-linear
              v-if="isLoading"
              indeterminate
              color="primary"
            ></v-progress-linear>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr class="upload-salaries-tr">
                    <th class="text-start">Sr no</th>
                    <th class="text-start">EmpID</th>
                    <th class="text-start">Name</th>
                    <th class="text-start">Date of Joining</th>
                    <th class="text-start">Branch Name</th>
                    <th class="text-start">Grade Name</th>
                    <th class="text-start">Region Name</th>
                    <th class="text-start">Department Name</th>
                    <th class="text-start">Employee Bank Name</th>
                    <th class="text-start">Designation Code</th>
                    <th class="text-start">Designation Name</th>
                    <th class="text-start">Employee Bank Account No</th>
                    <th class="text-start">RTGS</th>
                    <th class="text-start">Employee Bank Branch Name</th>
                    <th class="text-start">PF No</th>
                    <th class="text-start">PF UAN</th>
                    <th class="text-start">Esic No</th>
                    <th class="text-start">Present Days</th>
                    <th class="text-start">Absent</th>
                    <th class="text-start">Casual Leaves</th>
                    <th class="text-start">Privilage Leaves</th>
                    <th class="text-start">Basic Salary</th>
                    <th class="text-start">H R A</th>
                    <th class="text-start">Dearness Allowance</th>
                    <th class="text-start">Conveyance Allowance</th>
                    <th class="text-start">Bonus M</th>
                    <th class="text-start">Incentives</th>
                    <th class="text-start">Special Allowance</th>
                    <th class="text-start">Other Allowance</th>
                    <th class="text-start">Mobile Charges</th>
                    <th class="text-start">Arrears</th>
                    <th class="text-start">Communication</th>
                    <th class="text-start">Leave Salary</th>
                    <th class="text-start">Medical Allowance</th>
                    <th class="text-start">Food Allowance</th>
                    <th class="text-start">Allowance</th>
                    <th class="text-start">Expenses Reimbursement</th>
                    <th class="text-start">Mobile Allowance</th>
                    <th class="text-start">Educational Allowance</th>
                    <th class="text-start">Washing Allowance</th>
                    <th class="text-start">OT</th>
                    <th class="text-start">City Allowance</th>
                    <th class="text-start">Special Traveling Allowance</th>
                    <th class="text-start">Night Shift Allowance</th>
                    <th class="text-start">Fixed Allowance 01</th>
                    <th class="text-start">Leave Encashment</th>
                    <th class="text-start">Gross Salary</th>
                    <th class="text-start">Incentive 1</th>
                    <th class="text-start">Incentive 2</th>
                    <th class="text-start">Incentive 3</th>
                    <th class="text-start">Incentive 4</th>
                    <th class="text-start">Other Deduction</th>
                    <th class="text-start">Other Earning</th>
                    <th class="text-start">ESIC</th>
                    <th class="text-start">Profession Tax</th>
                    <th class="text-start">LWF</th>
                    <th class="text-start">TDS</th>
                    <th class="text-start">Provident Fund</th>
                    <th class="text-start">Total Deducations</th>
                    <th class="text-start">Net Pay</th>
                    <th class="text-start">Advance Against Statutory Bonus</th>
                    <th class="text-start">Meal Allowance</th>
                    <th class="text-start">Leave Encashment 1</th>
                    <th class="text-start">Travelling Allowance 400</th>
                    <th class="text-start">Travelling Allowance 250</th>
                    <th class="text-start">Stationary</th>
                    <th class="text-start">LTA</th>
                    <th class="text-start">Any Other Allowance</th>
                    <th class="text-start">Attendance Bonus</th>
                    <th class="text-start">Gratuity</th>
                    <th class="text-start">TSI Incentive Q1_Sub Rd Addition</th>
                    <th class="text-start">Gross OT</th>
                    <th class="text-start">Performance Incentive</th>
                    <th class="text-start">Telephone Allowance</th>
                    <th class="text-start">Bonus BB</th>
                    <th class="text-start">Transportation Charges</th>
                    <th class="text-start">Food Deduction</th>
                    <th class="text-start">Bonus (y)</th>
                  </tr>
                </thead>
                <tbody v-if="crude_salaries.length != 0">
                  <tr
                    v-for="(crudesalary, at) in crude_salaries"
                    :key="`crudesalary${at}`"
                  >
                    <td>{{ at + 1 }}</td>
                    <td>{{ crudesalary.emp_id || ""}}</td>
                    <td>{{ crudesalary.name || ""}}</td>
                    <td>{{ crudesalary.doj || ""}}</td>
                    <td>{{ crudesalary.branch || ""}}</td>
                    <td>{{ crudesalary.grade || ""}}</td>
                    <td>{{ crudesalary.region_name || ""}}</td>
                    <td>{{ crudesalary.department || ""}}</td>
                    <td>{{ crudesalary.emp_bank_name || ""}}</td>
                    <td>{{ crudesalary.designation_code || ""}}</td>
                    <td>{{ crudesalary.designation || ""}}</td>
                    <td>{{ crudesalary.bank_acc_no || ""}}</td>
                    <td>{{ crudesalary.rtgs || ""}}</td>
                    <td>{{ crudesalary.emp_bank_branch_name || ""}}</td>
                    <td>{{ crudesalary.pf_no || ""}}</td>
                    <td>{{ crudesalary.uan_no || ""}}</td>
                    <td>{{ crudesalary.esic_no || ""}}</td>
                    <td>{{ crudesalary.present_days || ""}}</td>
                    <td>{{ crudesalary.lwp_absent || ""}}</td>
                    <td>{{ crudesalary.casual_leave || ""}}</td>
                    <td>{{ crudesalary.priveleged_leave || ""}}</td>
                    <td>{{ crudesalary.basic_salary || ""}}</td>
                    <td>{{ crudesalary.hra || ""}}</td>
                    <td>{{ crudesalary.dearness_allowance || ""}}</td>
                    <td>{{ crudesalary.conveyance_allowance || ""}}</td>
                    <td>{{ crudesalary.bonus_m || ""}}</td>
                    <td>{{ crudesalary.incentives || ""}}</td>
                    <td>{{ crudesalary.special_allowance || ""}}</td>
                    <td>{{ crudesalary.other_allowance || ""}}</td>
                    <td>{{ crudesalary.mobile_charges || ""}}</td>
                    <td>{{ crudesalary.arrears || ""}}</td>
                    <td>{{ crudesalary.communication || ""}}</td>
                    <td>{{ crudesalary.leave_salary || ""}}</td>
                    <td>{{ crudesalary.medical_allowance || ""}}</td>
                    <td>{{ crudesalary.food_allowance || ""}}</td>
                    <td>{{ crudesalary.allowance || ""}}</td>
                    <td>{{ crudesalary.expense_reimbursement || ""}}</td>
                    <td>{{ crudesalary.mobile_allowance || ""}}</td>
                    <td>{{ crudesalary.educational_allowance || ""}}</td>
                    <td>{{ crudesalary.washing_allowance || ""}}</td>
                    <td>{{ crudesalary.ot || ""}}</td>
                    <td>{{ crudesalary.city_allowance || ""}}</td>
                    <td>{{ crudesalary.special_travelling_allowance || ""}}</td>
                    <td>{{ crudesalary.night_shift_allowance || ""}}</td>
                    <td>{{ crudesalary.fixed_allowance || ""}}</td>
                    <td>{{ crudesalary.leave_encashment || ""}}</td>
                    <td>{{ crudesalary.gross_salary || ""}}</td>
                    <td>{{ crudesalary.incentive_1 || ""}}</td>
                    <td>{{ crudesalary.incentive_2 || ""}}</td>
                    <td>{{ crudesalary.incentive_3 || ""}}</td>
                    <td>{{ crudesalary.incentive_4 || ""}}</td>
                    <td>{{ crudesalary.other_deduction || ""}}</td>
                    <td>{{ crudesalary.other_earning || ""}}</td>
                    <td>{{ crudesalary.esic || ""}}</td>
                    <td>{{ crudesalary.profession || ""}}</td>
                    <td>{{ crudesalary.lwf || ""}}</td>
                    <td>{{ crudesalary.tds || ""}}</td>
                    <td>{{ crudesalary.provident_fund || ""}}</td>
                    <td>{{ crudesalary.total_deductions || ""}}</td>
                    <td>{{ crudesalary.net_pay || ""}}</td>
                    <td>{{ crudesalary.advance_against_statutory_bonus || ""}}</td>
                    <td>{{ crudesalary.meal_allowance || ""}}</td>
                    <td>{{ crudesalary.leave_encashment_1 || ""}}</td>
                    <td>{{ crudesalary.travelling_allowance_400 || ""}}</td>
                    <td>{{ crudesalary.travelling_allowance_250 || ""}}</td>
                    <td>{{ crudesalary.stationary || ""}}</td>
                    <td>{{ crudesalary.lta || ""}}</td>
                    <td>{{ crudesalary.any_other_allowance || ""}}</td>
                    <td>{{ crudesalary.attendance_bonus || ""}}</td>
                    <td>{{ crudesalary.gratuity || ""}}</td>
                    <td>{{ crudesalary.tsi_incentive_q1_sub_rd_addition || ""}}</td>
                    <td>{{ crudesalary.gross_ot || ""}}</td>
                    <td>{{ crudesalary.performance_incentive || ""}}</td>
                    <td>{{ crudesalary.telephone_allowance || ""}}</td>
                    <td>{{ crudesalary.bonus_bb || ""}}</td>
                    <td>{{ crudesalary.transportation_charges || ""}}</td>
                    <td>{{ crudesalary.food_deduction || ""}}</td>
                    <td>{{ crudesalary.bonus_y || ""}}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!--End of Table -->
          </v-card>
        </v-col>
      </v-row>
      <v-dialog width="950" v-model="TicketsImageDialog">
        <v-carousel height="600" hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <v-sheet height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <v-img
                  :src="mediaUrl + slide"
                  contain
                  height="600"
                  max-width="950"
                />
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "ticketsPage",
  data() {
    return {
      filter: {
        month_year: "",
        month: "",
        year: "",
        expense_id: "",
      },
      crude_salaries: [],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      month_menu: false,
      isLoading: false,
      isExcelloading: false,
      TicketsImageDialog: false,
      slides: [],
    };
  },
  watch: {
    page: "getData",
    month_menu: "getMonthYear",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&user_id=" +
        this.$route.params.userId;
      let response = await axios.get(`salaries?${query}`);
      this.crude_salaries = response.data.data;
      this.count = response.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
    async generateExcel() {
      this.isExcelloading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let query = "search_keyword=" + this.search_keyword;
      let response = await axios.get(`tickets/export?${query}`);
      window.open(`${response.data.data}`, "_blank");
      this.isExcelloading = false;
      console.log(response);
    },
    getMonthYear() {
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
    },
  },
};
</script>
