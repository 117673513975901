<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" class="mt-4 ml-4">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-text="'Monthly Attendances'"
          ></span>
        </v-col>
      </v-row>

      <!-- Filters -->
      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData"
                    @change="page = 1"
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="employee"
                    v-model="filter.user_id"
                    :items="employees"
                    label="Employee"
                    @input="getData"
                    prepend-icon="mdi-account"
                    :error-messages="errors.user_id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="3">
                  <v-menu
                    v-model="month_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.month_year"
                        label="Year - Month"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="filter.month_year = null"
                        :error-messages="errors.month"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.month_year"
                      type="month"
                      @change="(month_menu = false), getData()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- / Filters -->
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="monthlyAttendances.length != 0"
              ><v-spacer></v-spacer
              ><v-btn
                class="primary mx-4"
                v-if="ExportMonthlyAttendances.length == 0"
                @click="addToExcelData()"
                ><span v-if="isExcelloading"> Generating Excel... </span>
                <span v-else>Generate Excel</span></v-btn
              >
              <v-btn v-else class="success mx-4"
                ><download-excel
                  :title="title"
                  :data="ExportMonthlyAttendances"
                  :fields="excel_fields"
                  worksheet="MonthlyAttendances"
                  name="MonthlyAttendances.xls"
                  >Download Excel
                </download-excel></v-btn
              ></v-card-title
            >
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left" style="min-width: 150px">
                      Employee Name
                    </th>
                    <th class="text-left" style="min-width: 150px">
                      Supervisor
                    </th>
                    <th v-for="c in totalDaysInMonth" :key="`days${c}`">
                      {{ c }}
                    </th>
                    <th class="text-left" style="min-width: 150px">
                      Present count
                    </th>
                    <th class="text-left" style="min-width: 150px">
                      Absent count
                    </th>
                    <th class="text-left" style="min-width: 150px">
                      Leave count
                    </th>
                    <th class="text-left" style="min-width: 150px">
                      Meeting count
                    </th>
                  </tr>
                </thead>
                <tbody v-if="monthlyAttendances.length != 0">
                  <tr v-for="(user, i) in monthlyAttendances" :key="`user${i}`">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="user.active == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="green"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>User is Active</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="user.active == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="red"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>User is InActive</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td>{{ user.name || "" }}</td>
                    <td>{{ user.supervisor.name || "" }}</td>

                    <td v-for="day in totalDaysInMonth" :key="`days${day}`">
                      <span v-if="user.attendances[day]">
                        {{
                          user.attendances[day].session_type
                            ? user.attendances[day].session_type.description
                                .charAt(0)
                                .toUpperCase()
                            : "A"
                        }}
                      </span>
                      <span v-else>
                        <span
                          v-if="
                            !user.doj ||
                            (user.doj &&
                              day >= parseInt(user.doj.split('-')[2]))
                          "
                        >
                          {{
                            new Date(
                              filter.year,
                              filter.month - 1,
                              day
                            ).getDay() === 0
                              ? "W"
                              : "A"
                          }}
                        </span>
                        <span v-else> A </span>
                      </span>
                    </td>

                    <!-- 
                    <td v-for="day in totalDaysInMonth" :key="`days${day}`">
                      <span v-if="user.attendances[day]">
                        {{
                          user.attendances[day].session_type
                            ? user.attendances[day].session_type.description
                                .charAt(0)
                                .toUpperCase()
                            : "A"
                        }}
                      </span>
                      <span v-else>
                        <span
                          v-if="
                            user.doj && day >= parseInt(user.doj.split('-')[2])
                          "
                        >
                          {{
                            new Date(filter.year, filter.month - 1, day).getDay() 
                              ? "W"
                              : "A"
                          }}
                        </span>
                        <span
                          v-else-if="isThirdOrFourthSaturday(year, month, day)"
                        >
                          W
                        </span>
                        <span v-else> A </span>
                      </span>
                    </td> -->

                    <td>{{ user.present_count }}</td>
                    <td>{{ user.absent_count }}</td>
                    <td>{{ user.leave_count }}</td>
                    <td>{{ user.meeting_count }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      :colspan="`${7 + totalDaysInMonth}`"
                      class="text-center"
                    >
                      No data found
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "monthlyAttendancesPage",
  data() {
    return {
      filter: {
        month_year: "",
        month: "",
        year: "",
        user_id: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      supervisor_id: "",

      month_menu: false,
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],

      totalDaysInMonth: "",
      isLoading: false,
      isExcelloading: false,
      monthlyAttendances: [],
      ExportMonthlyAttendances: [],
      All_monthlyAttendances: [],
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Supervisor: "supervisor",
      },
    };
  },
  watch: {
    page: "getData",
    month_menu: "getMonthYear",
  },

  mounted() {
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.supervisor_id = this.user.id;
    }
    this.getMasters();
  },
  methods: {
    //    isThirdOrFourthSaturday(year, month, day) {
    //   const date = new Date(year, month - 1, day);
    //   const dayOfWeek = date.getDay();
    //   const weekOfMonth = Math.ceil((date.getDate() - dayOfWeek) / 7);

    //   // Check if it's the 3rd or 4th Saturday (dayOfWeek === 6 for Saturday)
    //   return dayOfWeek === 6 && (weekOfMonth === 3 || weekOfMonth === 4);
    // },

    async getMasters() {
      let masters = await axios.get(`user_attendances/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (employee.supervisor_id == this.user.id) {
            this.employees.push({
              id: employee.id,
              text: employee.name,
              value: employee.id,
            });
          }
        } else {
          this.employees.push({
            id: employee.id,
            text: employee.name,
            value: employee.id,
          });
        }
      });
    },
    async getData() {
      this.isLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&user_id=" +
        this.filter.user_id;
      await axios
        .get(`monthly_attendances?${query}`)
        .then((monthlyAttendances) => {
          this.ExportMonthlyAttendances = [];
          this.monthlyAttendances = monthlyAttendances.data.data;
          console.log(this.monthlyAttendances);
          this.count = monthlyAttendances.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    async addToExcelData() {
      this.All_monthlyAttendances = [];
      this.isExcelLoading = true;
      if (this.filter.month != this.current_month) {
        this.totalDaysInMonth = moment(
          this.filter.month_year,
          "YYYY-M"
        ).daysInMonth();
      } else {
        this.totalDaysInMonth = parseInt(this.today);
      }
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
        this.title =
          "Monthly Attendance | " + this.filter.month + " " + this.filter.year;
      }
      for (var i = 1; i <= this.totalDaysInMonth; i++) {
        let date = i;
        this.excel_fields[`D${date}`] = `D${date}`;
      }
      this.excel_fields["Present Count"] = "present_count";
      this.excel_fields["Absent Count"] = "absent_count";
      this.excel_fields["Leave Count"] = "leave_count";
      this.excel_fields["Meeting Count"] = "meeting_count";
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&user_id=" +
        this.filter.user_id;
      await axios
        .get(`monthly_attendances?${query}`)
        .then((response) => {
          this.All_monthlyAttendances = response.data.data;
          let sr_no = 1;
          this.All_monthlyAttendances.forEach((user) => {
            let excel_attendances = {
              sr_no: sr_no++,
              name: user.name ? user.name.toUpperCase() : "",
              supervisor: user.supervisor_id
                ? user.supervisor.name.toUpperCase()
                : "",
              present_count: user.present_count,
              absent_count: user.absent_count,
              leave_count: user.leave_count,
              meeting_count: user.meeting_count,
            };
            for (var i = 1; i <= this.totalDaysInMonth; i++) {
              let date = i;
              if (
                user.attendances[date] &&
                user.attendances[date].session_type
              ) {
                excel_attendances["D" + date] = user.attendances[
                  date
                ].session_type.description
                  .charAt(0)
                  .toUpperCase();
              } else {
                // Check if it's a Sunday (0 corresponds to Sunday)
                if (
                  new Date(
                    this.filter.year,
                    this.filter.month - 1,
                    date
                  ).getDay() === 0
                ) {
                  excel_attendances["D" + date] = "W";
                } else {
                  excel_attendances["D" + date] = "A";
                }
              }
            }

            //  for (var i = 1; i <= this.totalDaysInMonth; i++) {
            //   let date = i;
            //   if (
            //     user.attendances[date] &&
            //     user.attendances[date].session_type &&
            //     user.attendances[date].session_type.description
            //   ) {
            //     excel_attendances["D" + date] = user.attendances[
            //       date
            //     ].session_type.description
            //       .charAt(0)
            //       .toUpperCase();
            //   } else {
            //     excel_attendances["D" + date] = "A"; // Use empty string for blank cell
            //   }
            // }
            this.ExportMonthlyAttendances.push(excel_attendances);
          });
          this.isExcelLoading = false;
        })
        .catch(() => {
          this.isExcelLoading = false;
        });
      //   this.All_monthlyAttendances = All_monthlyAttendances.data.data;
    },
    getMonthYear() {
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];

        this.totalDaysInMonth = moment(
          this.filter.month_year,
          "YYYY-M"
        ).daysInMonth();
        console.log(this.totalDaysInMonth);
      }
    },
  },
};
</script>
