<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" class="mt-4 ml-4">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-text="'Date Wise Expenses'"
          ></span>
        </v-col>
      </v-row>

      <!-- Filters -->
      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData"
                    @change="page = 1"
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="employee"
                    v-model="filter.user_id"
                    :items="employees"
                    label="Employee"
                    @input="getData"
                    prepend-icon="mdi-account"
                    :error-messages="errors.user_id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="3">
                  <v-menu
                    v-model="month_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.month_year"
                        label="Year - Month"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="filter.month_year = null"
                        :error-messages="errors.month"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.month_year"
                      type="month"
                      @change="(month_menu = false), getData()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- / Filters -->
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="date_wise_expenses.length != 0"
              ><v-spacer></v-spacer>
              <v-col md="3" class="mt-5">
                <v-text-field
                  v-model="total_expense_amount"
                  outlined
                  dense
                  readonly
                  disabled
                  label="Total Expense amount"
                  :messages="`Total Expense amount for ${filter.month_year}`"
                  prepend-inner-icon="mdi-cash"
                ></v-text-field>
              </v-col>
              <!-- Approve All Selected Expense -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    x-large
                    v-on="on"
                    @click="UpdateApprovalStatus('APPROVE')"
                    icon
                    :loading="isApproving"
                    ><v-icon>mdi-cash-check</v-icon></v-btn
                  >
                </template>
                <span>Approve All</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    v-bind="attrs"
                    x-large
                    v-on="on"
                    @click="UpdateApprovalStatus('DENY')"
                    icon
                    :loading="isDenying"
                    ><v-icon>mdi-cash-remove</v-icon></v-btn
                  >
                </template>
                <span>Denied All</span>
              </v-tooltip>
              <!--End of Approve All Selected Expense -->
              <!-- Download Excel -->

              <v-btn
                class="primary mx-4"
                v-if="ExportExpences.length == 0"
                @click="addToExcelData()"
                ><span v-if="isExcelloading"> Generating Excel... </span>
                <span v-else>Generate Excel</span></v-btn
              >
              <v-btn v-else class="success mx-4"
                ><download-excel
                  :title="title"
                  :data="ExportExpences"
                  :fields="excel_fields"
                  worksheet="Date-Wise-Expense-Report"
                  name="Date-Wise-Expense-Report.xls"
                  >Download Excel
                </download-excel></v-btn
              ></v-card-title
            >
            <!--end of Download Excel -->
            <!-- Table -->
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">
                      <v-checkbox
                        @click="selectAll()"
                        label="Select All"
                        v-model="is_select_all"
                      ></v-checkbox>
                    </th>
                    <th class="text-left" style="min-width: 150px">Date</th>
                    <th class="text-left">Amount</th>
                    <th class="text-left">Count</th>
                    <th class="text-left">Action</th>
                  </tr>
                </thead>
                <tbody v-if="date_wise_expenses.length != 0">
                  <tr
                    v-for="(date_expense, i) in date_wise_expenses"
                    :key="`date_expense${i}`"
                  >
                    <td style="min-width: 85px">
                      {{ i + 1 }}
                    </td>
                    <td>
                      <v-checkbox
                        v-model="date_expense.is_selected"
                      ></v-checkbox>
                    </td>
                    <td>{{ date_expense.pjp_date || "" }}</td>
                    <td>{{ date_expense.total_amount || "" }}</td>
                    <td>{{ date_expense.count || "" }}</td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mx-2"
                            fab
                            x-small
                            color="primary"
                            @click="getExpenses(date_expense)"
                          >
                            <v-icon dark> mdi-eye </v-icon>
                          </v-btn>
                        </template>
                        <span>View Expenses</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!--End of Table -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Expenses Dialog Box -->
    <v-dialog v-model="show_expenses" width="900">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col align="center">
              <v-card-text>
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Sr No</th>
                        <!-- <th class="text-left">
                          <v-checkbox
                            @click="selectAll()"
                            label="Select All"
                            v-model="is_select_all"
                          ></v-checkbox>
                        </th> -->
                        <th class="text-left" style="min-width: 150px">
                          Employee Name
                        </th>
                        <th class="text-left">Trevel Mode</th>
                        <th class="text-left">Expense type Mode</th>
                        <th class="text-left">From</th>
                        <th class="text-left">To</th>
                        <th class="text-left">Amount</th>
                        <th class="text-left" style="min-width: 150px">
                          Supervisor
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="expenses.length != 0">
                      <tr v-for="(expense, i) in expenses" :key="`expense${i}`">
                        <td style="min-width: 85px">
                          <v-tooltip bottom v-if="expense.is_approved == 1">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                dark
                                color="green"
                              >
                                mdi-circle
                              </v-icon>
                            </template>
                            <span>Approved</span>
                          </v-tooltip>
                          <v-tooltip bottom v-if="expense.is_approved == 0">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                dark
                                color="red"
                              >
                                mdi-circle
                              </v-icon>
                            </template>
                            <span>Not Approved</span>
                          </v-tooltip>
                          &nbsp;
                          {{ i + 1 }}
                        </td>
                        <!-- <td>
                          <v-checkbox
                            v-model="expense.is_selected"
                          ></v-checkbox>
                        </td> -->
                        <td>{{ expense.user.name || "" }}</td>
                        <td>{{ expense.travel_mode.code || "" }}</td>
                        <td>{{ expense.expense_type.code || "" }}</td>
                        <td>{{ expense.from || "" }}</td>
                        <td>{{ expense.to || "" }}</td>
                        <td>{{ expense.amount || "" }}</td>
                        <td>{{ expense.supervisor.name || "" }}</td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="9" class="text-center">No data found</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-btn color="success" @click="show_expenses = false">
                  OK
                </v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "expensesPage",
  data() {
    return {
      filter: {
        month_year: "",
        month: "",
        year: "",
        expense_id: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      supervisor_id: "",
      month_menu: false,
      isApproving: false,
      isDenying: false,
      show_expenses: false,
      expenses: [],
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],

      isLoading: false,
      is_select_all: false,
      isExcelloading: false,
      date_wise_expenses: [],
      ExportExpences: [],
      DateWiseExpenses: [],
      excel_fields: {
        "Sr No": "sr_no",
        Date: "date",
        "Total Amount": "total_amount",
        Name: "name",
        "Travel Mode": "travel_mode",
        "Expense Type Mode": "expense_type_mode",
        From: "from",
        To: "to",
        Amount: "amount",
        "Is Approved": "status",
        Supervisor: "supervisor",
      },
    };
  },
  watch: {
    page: "getData",
    month_menu: "getMonthYear",
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`pjp_expenses/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.name,
          value: employee.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&user_id=" +
        this.filter.user_id;
      await axios
        .get(`date_wise_expenses?${query}`)
        .then((date_wise_expenses) => {
          this.ExportExpences = [];
          this.date_wise_expenses = date_wise_expenses.data.data;
          this.total_expense_amount = 0;
          this.date_wise_expenses.forEach((date) => {
            date.is_selected = false;
            this.total_expense_amount += parseInt(date.total_amount);
          });
          this.count = date_wise_expenses.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getExpenses(date_expense) {
      if (date_expense.expenses.length > 0) {
        this.show_expenses = true;
        this.expenses = date_expense.expenses;
      }
    },

    async addToExcelData() {
      this.DateWiseExpenses = [];
      this.isExcelLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
        this.title =
          "Date Wise Expense Report | " +
          this.filter.month +
          " " +
          this.filter.year;
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&user_id=" +
        this.filter.user_id;
      await axios
        .get(`date_wise_expenses?${query}`)
        .then((response) => {
          this.DateWiseExpenses = response.data.data;
          let sr_no = 1;
          this.DateWiseExpenses.forEach((date_expense) => {
            date_expense.expenses.forEach((expense, r) => {
              let single_row = {};
              if (r == 0) {
                single_row = {
                  sr_no: sr_no++,
                  date: date_expense.pjp_date,
                  total_amount: date_expense.total_amount,
                  name: expense.user.name
                    ? expense.user.name.toUpperCase()
                    : "",
                  travel_mode: expense.travel_mode.code
                    ? expense.travel_mode.code.toUpperCase()
                    : "",
                  expense_type_mode: expense.expense_type.code
                    ? expense.expense_type.code.toUpperCase()
                    : "",
                  from: expense.from ? expense.from.toUpperCase() : "",
                  to: expense.to ? expense.to.toUpperCase() : "",
                  amount: expense.amount ? expense.amount.toUpperCase() : "",
                  status: expense.is_approved == 1 ? "YES" : "NO",
                  supervisor: expense.supervisor_id
                    ? expense.supervisor.name.toUpperCase()
                    : "",
                };
              } else {
                single_row = {
                  name: expense.user.name
                    ? expense.user.name.toUpperCase()
                    : "",
                  travel_mode: expense.travel_mode.code
                    ? expense.travel_mode.code.toUpperCase()
                    : "",
                  expense_type_mode: expense.expense_type.code
                    ? expense.expense_type.code.toUpperCase()
                    : "",
                  from: expense.from ? expense.from.toUpperCase() : "",
                  to: expense.to ? expense.to.toUpperCase() : "",
                  amount: expense.amount ? expense.amount.toUpperCase() : "",
                  status: expense.is_approved == 1 ? "YES" : "NO",
                  supervisor: expense.supervisor_id
                    ? expense.supervisor.name.toUpperCase()
                    : "",
                };
              }
              this.ExportExpences.push(single_row);
            });
          });
          console.log(this.ExportExpences);
          this.isExcelLoading = false;
        })
        .catch(() => {
          this.isExcelLoading = false;
        });
      //   this.DateWiseExpenses = DateWiseExpenses.data.data;
    },
    getMonthYear() {
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
    },
    async UpdateApprovalStatus(type) {
      if (type == "APPROVE") {
        this.isApproving = true;
      } else {
        this.isDenying = true;
      }
      let filtered_expense = [];
      this.date_wise_expenses.forEach((date_expense) => {
        if (date_expense.is_selected == 1) {
          date_expense.expenses.forEach((expense) => {
            expense.is_approved = type == "APPROVE" ? 1 : 0;
            filtered_expense.push(expense);
          });
        }
      });
      let payload = {
        datas: filtered_expense,
      };
      await axios
        .post(`/expenses/update_approval_status`, payload)
        .then(() => {});
      this.isApproving = false;
      this.isDenying = false;
    },
    selectAll() {
      console.log(this.is_select_all);
      this.date_wise_expenses.forEach((expense) => {
        expense.is_selected = this.is_select_all;
      });
    },
  },
};
</script>
