<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" class="mt-4 ml-4">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Leaves'"></span>
          <v-btn class="mx-2 mb-2" fab x-small color="primary" to="/user-leaves/create">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field v-model="search_keyword" label="Search ... " prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData" @change="page = 1"></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="employee" v-model="user_id" :items="employees" label="Employee" @input="getData"
                    prepend-icon="mdi-account"></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="2">
                  <v-autocomplete ref="supervisor" v-model="supervisorId" :items="supervisors" label="Supervisor"
                    @input="getData" prepend-icon="mdi-account"></v-autocomplete>
                </v-col>
                <v-col sm="2">
                  <v-autocomplete ref="leave type" v-model="leave_type_id" :items="leaveTypes" label="Leave Type"
                    @input="getData" prepend-icon="mdi-account"></v-autocomplete>
                </v-col>
                <v-col sm="2">
                  <v-autocomplete ref="status" v-model="active" :items="statuses" label="Employee Status" @input="getData"
                    prepend-icon="mdi-toggle-switch"></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-menu v-model="month_menu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="filter.month_year" label="Year - Month" prepend-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on" clearable @click:clear="filter.month_year = null"
                        :error-messages="errors.month"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.month_year" type="month"
                      @change="(month_menu = false), getData()"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="userAttendances.length != 0"><v-spacer></v-spacer><v-btn class="primary mx-4"
                v-if="ExportUserLeaves.length == 0" @click="generateExcel()"><span v-if="isExcelloading"> Generating
                  Excel... </span>
                <span v-else>Generate Excel</span></v-btn>
              <v-btn target="_blank" :href="`${mediaUrl}store-vyu/exports/UserLeavesExport.xlsx`" v-else
                class="success mx-4"></v-btn></v-card-title>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Employee Name</th>
                    <th class="text-left">From Date</th>
                    <th class="text-left">To Date</th>
                    <th class="text-left">Remarks</th>
                    <th class="text-left">Leave Type</th>
                    <th class="text-left">Supervisor</th>
                    <th class="text-left">Is Approved</th>
                    <th class="text-left">Decline Reason</th>
                    <th class="text-left">Attachments</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody v-if="userAttendances.length != 0">
                  <tr v-for="(user, i) in userAttendances" :key="`user${i}`">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="user.is_approved == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small dark color="green">
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>User is Active</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="user.is_approved == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small dark color="red">
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>User is InActive</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td>{{ user.user.name || "" }}</td>
                    <td>{{ user.leave_from_date || "" }}</td>
                    <td>{{ user.leave_to_date || "" }}</td>
                    <td>{{ user.remarks || "" }}</td>
                    <td>{{ user.leave_type.code || "" }}</td>
                    <td>{{ user.user.supervisor ? user.user.supervisor.name : "" }}</td>
                    <td>{{ user.is_approved == 1 ? "YES" : "NO" }}</td>
                    <td>{{ user.decline_reason || "" }}</td>
                    <td>
                      <a v-if="user.attachment" target="_blank" :href="`${mediaUrl}store-vyu/${user.attachment}`">View
                        Attachment</a>
                    </td>

                    <td class="text-right" v-if="user.leave_flag == false">
                      <v-btn class="mx-2" fab x-small color="primary" :to="`/user-leaves/${user.id}`">
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="11" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "userAttendancesPage",
  data() {
    return {
      filter: {
        month_year: "",
        month: "",
        year: "",
        is_approved: "",
        leave_date: "",
        current_month: "",
      },
      search_keyword: "",
      supervisor_id: "",
      leave_type_id: "",
      user_id: "",
      active: true,
      month_menu: false,
      attendance_type: "Leave",
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      statuses: [
        {
          id: 0,
          text: "Select Employee Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: true },
        { id: "INACTIVE", text: "INACTIVE", value: false },
      ],

      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      leaveTypes: [
        {
          id: 0,
          text: "Select Leave Type",
          value: "",
        },
      ],
      isLoading: false,
      isExcelloading: false,
      ExportUserLeaves: [],
      userAttendances: [],
    };
  },
  watch: {
    page: "getData",
    month_menu: "getMonthYear",
  },
  created() {
    const Approved = this.$route.query.is_approved;
    console.log("Status Type:", Approved);
    this.filter.is_approved = Approved;
    const leave_date = this.$route.query.leave_date;
    console.log("leave_date:", leave_date);
    this.filter.leave_date = leave_date;
    const current_month = this.$route.query.current_month;
    console.log("current_month:", current_month);
    this.filter.current_month = current_month;
    this.getData();
  },
  mounted() {
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.supervisor_id = this.user.id;
    }
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`user_attendances/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (employee.supervisor_id == this.user.id) {
            this.employees.push({
              id: employee.id,
              text: employee.name,
              value: employee.id,
            });
          }
        } else {
          this.employees.push({
            id: employee.id,
            text: employee.name,
            value: employee.id,
          });
        }
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (supervisor.id == this.user.id) {
            this.supervisors.push({
              id: supervisor.id,
              text: supervisor.name,
              value: supervisor.id,
            });
          }
        } else {
          this.supervisors.push({
            id: supervisor.id,
            text: supervisor.name,
            value: supervisor.id,
          });
        }
      });
      // Leave Types
      masters.leaveTypes.forEach((leaveType) => {
        this.leaveTypes.push({
          id: leaveType.id,
          text: leaveType.code,
          value: leaveType.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.filter.is_approved = this.filter.is_approved ? this.filter.is_approved : "";
      this.filter.leave_date = this.filter.leave_date ? this.filter.leave_date : "";
      this.filter.current_month = this.filter.current_month ? this.filter.current_month : "";
      this.active = this.active ? true : false;
      this.supervisorId = this.supervisorId ? this.supervisorId : "";
      this.user_id = this.user_id ? this.user_id : "";
      this.attendance_type = this.attendance_type ? this.attendance_type : "";
      this.leave_type_id = this.leave_type_id ? this.leave_type_id : "";
      let userAttendances = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&leaveTypeId=" +
        this.leave_type_id +
        "&attendanceType=" +
        this.attendance_type +
        "&employee_status=" +
        this.active +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&userId=" +
        this.user_id +
        "&supervisorId=" +
        this.supervisorId +
        "&is_approved=" +
        this.filter.is_approved +
        "&current_month=" +
        this.filter.current_month +
        "&leave_date=" +
        this.filter.leave_date;
      userAttendances = await axios.get(`user_attendances?${query}`);
      this.userAttendances = userAttendances.data.data;
      console.log(this.userAttendances);
      this.count = userAttendances.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },

    async generateExcel() {
      let query =
        this.search_keyword +
        "&leaveTypeId=" +
        this.leave_type_id +
        "&attendanceType=" +
        this.attendance_type +
        "&employee_status=" +
        this.active +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&userId=" +
        this.user_id +
        "&supervisorId=" +
        this.supervisor_id;
      let response = await axios.get(`user_leaves_Exports?${query}`);
      console.log(response.data.data);
      if (response) {
        window.open(`${response.data.data}`, "_blank");
      }
    },

    getMonthYear() {
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
    },
  },
};
</script>
