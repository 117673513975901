export default {
  namespaced: true,
  state: {
    baseURL: null,
    mediaURL: null,
    rowsPerPage: 20,
  },
  getters: {
    getBaseUrl(state) {
      return state.baseURL;
    },
    getMediaUrl(state) {
      return state.mediaURL;
    },
    getRowsPerPage(state) {
      return state.rowsPerPage;
    },
  },
  mutations: {
    SET_BASE_URL(state, data) {
      state.baseURL = data;
    },
    SET_MEDIA_URL(state, data) {
      state.mediaURL = data;
    },
  },
  actions: {
    setBaseUrl({ commit }, url) {
      commit("SET_BASE_URL", url);
    },
    setMediaUrl({ commit }, url) {
      commit("SET_MEDIA_URL", url);
    },
  },
};
