import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

// Layout
import UnAuthLayout from "../views/layouts/unauth-layout.vue";
import AuthLayout from "../views/layouts/auth-layout.vue";

// Static
import HomePage from "../views/home";
// import EmployeApprove from "../views/home/employeonleave.vue";

// Auth
import LoginPage from "../views/auth/login.vue";

// Companies
import CompaniesPage from "../views/companies/index.vue";
import CompanyCreatePage from "../views/companies/create.vue";
import CompanyIdPage from "../views/companies/id.vue";

// Users
import UsersPage from "../views/users/index.vue";
import SupervisorPage from "../views/users/supervisor.vue";
import UserCreatePage from "../views/users/create.vue";
import SupervisorCreatePage from "../views/users/supervisorcreate.vue";
import UserIdPage from "../views/users/id.vue";
import UsersUploadPage from "../views/users/upload.vue";

// Timestamps
import TimestampsPage from "../views/timestamps/index.vue";

// User Attendances
import UserAttendancesPage from "../views/user-attendances/index.vue";
import UserAttendanceCreatePage from "../views/user-attendances/create.vue";
import UserAttendanceIdPage from "../views/user-attendances/id.vue";

// User Regularizations
import UserRegularizationsPage from "../views/user-regularizations/index.vue";
import UserRegularizationCreatePage from "../views/user-regularizations/create.vue";
import UserRegularizationIdPage from "../views/user-regularizations/id.vue";

// User Leaves
import UserLeavesPage from "../views/user-leaves/index.vue";
import UserLeaveCreatePage from "../views/user-leaves/create.vue";
import UserLeaveIdPage from "../views/user-leaves/id.vue";

// PJPS
import PJPsPage from "../views/pjps/index.vue";
import PJPCreatePage from "../views/pjps/create.vue";
import PJPIdPage from "../views/pjps/id.vue";
import PjpsUploadPage from "../views/pjps/upload.vue";
import CopyPjps from "../views/pjps/copy_pjps.vue";

// PJP Expenses
import PJPExpensesPage from "../views/pjp-expenses/index.vue";
import PJPExpenseCreatePage from "../views/pjp-expenses/create.vue";
import PJPExpenseIdPage from "../views/pjp-expenses/id.vue";

// PJP references
import PJPReferencesPage from "../views/pjp-references/index.vue";
import PJPReferenceCreatePage from "../views/pjp-references/create.vue";
import PJPReferenceIdPage from "../views/pjp-references/id.vue";

// Reports
import MonthlyAttendancesPage from "../views/reports/monthly-attendances.vue";
import MonthlyattendancesPage from "../views/reports/monthly_attendances.vue";

import ExpensesPage from "../views/reports/expenses.vue";
import DateWiseExpensesPage from "../views/reports/date-wise-expenses.vue";
import PjpReportsPage from "../views/reports/pjps.vue";

// Values
import Values from "@/views/values/index.vue";
import ValueCreate from "@/views/values/create.vue";
import ValueId from "@/views/values/id.vue";

// ValueLists
import ValueLists from "@/views/value-lists/index.vue";
import ValueListUpload from "@/views/value-lists/upload.vue";
import ValueListCreate from "@/views/value-lists/create.vue";
import ValueListId from "@/views/value-lists/id.vue";

//geofence
import GeoFence from "@/views/geofence/geofenceCreate.vue";

//tickets
import TicketsCreataePage from "@/views/tickets/create.vue";
import TicketsIdPage from "@/views/tickets/id.vue";
import TicketsIndexPage from "@/views/tickets/index.vue";
import TicketsFollowUpsPage from "@/views/tickets/followups.vue";
import TicketsFollowUpsCreatePage from "@/views/tickets/create-followups.vue";
import TicketsFollowUpsIdPage from "@/views/tickets/id-followups.vue";

//faqs
import FaqsCreatePage from "@/views/faqs/create.vue";
import FaqsIdPage from "@/views/faqs/id.vue";
import FaqsIndexPage from "@/views/faqs/index.vue";
//salries
import SalariesUploadPage from "@/views/salaries/upload.vue";
import SalariesIndexPage from "@/views/salaries/index.vue";

//latters
import LettersIndexPage from "@/views/letters/index.vue";
import LettersCreatePage from "@/views/letters/create.vue";
import LettersIdPage from "@/views/letters/id.vue";
const routes = [
  // AuthLayout
  {
    path: "/auth",
    name: "AuthLayout",
    component: AuthLayout,
    children: [
      {
        path: "/home",
        name: "HomePage",
        component: HomePage,
      },
      // {
      //     path: "/home/user_leaves/approved",
      //     name: "HomEmployeApprovePage",
      //     component: EmployeApprove,
      // },
      // Comapnies
      {
        path: "/companies",
        name: "CompaniesPage",
        component: CompaniesPage,
      },
      {
        path: "/companies/create",
        name: "CompanyCreatePage",
        component: CompanyCreatePage,
      },
      {
        path: "/companies/:companyId",
        name: "CompanyIdPage",
        component: CompanyIdPage,
      },
      // Users
      {
        path: "/users",
        name: "UsersPage",
        component: UsersPage,
      },
      {
        path: "/supervisor",
        name: "SupervisorPage",
        component: SupervisorPage,
      },
      {
        path: "/users/create",
        name: "UserCreatePage",
        component: UserCreatePage,
      },
      {
        path: "/supervisor/create",
        name: "SupervisorCreatePage",
        component: SupervisorCreatePage,
      },
      {
        path: "/users/upload",
        name: "UsersUploadPage",
        component: UsersUploadPage,
      },
      {
        path: "/users/:userId",
        name: "UserIdPage",
        component: UserIdPage,
      },
      // Timestamps
      {
        path: "/timestamps",
        name: "TimestampsPage",
        component: TimestampsPage,
      },
      // User Attendances
      {
        path: "/user-attendances",
        name: "UserAttendancesPage",
        component: UserAttendancesPage,
      },
      // {
      //     path: "/user-attendances",
      //     name: "UserAttendancesPage",
      //     component: UserAttendancesPage,
      // },
      {
        path: "/user-attendances/create",
        name: "UserAttendanceCreatePage",
        component: UserAttendanceCreatePage,
      },
      {
        path: "/user-attendances/:userAttendanceId",
        name: "UserAttendanceIdPage",
        component: UserAttendanceIdPage,
      },
      // User Regularizations
      {
        path: "/user-regularizations",
        name: "UserRegularizationsPage",
        component: UserRegularizationsPage,
      },
      {
        path: "/user-regularizations/create",
        name: "UserRegularizationCreatePage",
        component: UserRegularizationCreatePage,
      },
      {
        path: "/user-regularizations/:userRegularizationId",
        name: "UserRegularizationIdPage",
        component: UserRegularizationIdPage,
      },
      // User Leaves
      {
        path: "/user-leaves",
        name: "UserLeavesPage",
        component: UserLeavesPage,
      },
      // {
      //     path: "/user-leaves/:is_approved",
      //     name: "UserLeavesPage",
      //     component: UserLeavesPage,
      // },
      {
        path: "/user-leaves/create",
        name: "UserLeaveCreatePage",
        component: UserLeaveCreatePage,
      },
      {
        path: "/user-leaves/:userLeaveId",
        name: "UserLeaveIdPage",
        component: UserLeaveIdPage,
      },
      // PJPs
      {
        path: "/pjps",
        name: "PJPsPage",
        component: PJPsPage,
      },
      {
        path: "/copy_pjps",
        name: "CopyPjps",
        component: CopyPjps,
      },
      {
        path: "/pjps/upload",
        name: "PjpsUploadPage",
        component: PjpsUploadPage,
      },
      {
        path: "/pjps/create",
        name: "PJPCreatePage",
        component: PJPCreatePage,
      },
      {
        path: "/pjps/:pjpId",
        name: "PJPIdPage",
        component: PJPIdPage,
      },

      // PJP Expenses
      {
        path: "/pjps/:pjpId/pjp-expenses",
        name: "PJPExpensesPage",
        component: PJPExpensesPage,
      },
      {
        path: "/pjps/:pjpId/pjp-expenses/create",
        name: "PJPExpenseCreatePage",
        component: PJPExpenseCreatePage,
      },
      {
        path: "/pjps/:pjpId/pjp-expenses/:pjpExpenseId",
        name: "PJPExpenseIdPage",
        component: PJPExpenseIdPage,
      },
      // PJP References
      {
        path: "/pjp-references",
        name: "PJPReferencesPage",
        component: PJPReferencesPage,
      },
      {
        path: "/pjp-references/create",
        name: "PJPReferenceCreatePage",
        component: PJPReferenceCreatePage,
      },
      {
        path: "/pjp-references/:pjpReferenceId",
        name: "PJPReferenceIdPage",
        component: PJPReferenceIdPage,
      },
      // Reports
      {
        path: "/reports/monthly-attendances",
        name: "MonthlyAttendancesPage",
        component: MonthlyAttendancesPage,
      },
      {
        path: "/reports/monthly_attendances",
        name: "MonthlyattendancesPage",
        component: MonthlyattendancesPage,
      },
      {
        path: "/reports/expenses",
        name: "ExpensesPage",
        component: ExpensesPage,
      },
      {
        path: "/reports/date-wise-expenses",
        name: "DateWiseExpensesPage",
        component: DateWiseExpensesPage,
      },
      {
        path: "/reports/pjps",
        name: "PjpReportsPage",
        component: PjpReportsPage,
      },
      // Values
      {
        path: "/values",
        name: "Values",
        component: Values,
      },
      {
        path: "/values/create",
        name: "ValueCreate",
        component: ValueCreate,
      },
      {
        path: "/values/:valueId",
        name: "ValueId",
        component: ValueId,
      },
      // ValueLists
      {
        path: "/value-lists",
        name: "ValueLists",
        component: ValueLists,
      },
      {
        path: "/value-lists/upload",
        name: "ValueListUpload",
        component: ValueListUpload,
      },
      {
        path: "/value-lists/create",
        name: "ValueListCreate",
        component: ValueListCreate,
      },
      {
        path: "/value-lists/:valueListId",
        name: "ValueListId",
        component: ValueListId,
      },
      {
        path: "/geofence/create",
        name: "Geofence",
        component: GeoFence,
      },
      //tickets
      {
        path: "/tickets/create",
        name: "TicketsCreataePage",
        component: TicketsCreataePage,
      },
      {
        path: "/tickets/:ticketId",
        name: "TicketsIdPage",
        component: TicketsIdPage,
      },
      {
        path: "/tickets",
        name: "TicketsIndexPage",
        component: TicketsIndexPage,
      },
      {
        path: "/tickets/:ticketId/follow-ups",
        name: "TicketsFollowUpsPage",
        component: TicketsFollowUpsPage,
      },
      {
        path: "/tickets/:ticketId/follow-ups/create",
        name: "TicketsFollowUpsCreatePage",
        component: TicketsFollowUpsCreatePage,
      },
      {
        path: "/tickets/:ticketId/follow-ups/:followupId",
        name: "TicketsFollowUpsIdPage",
        component: TicketsFollowUpsIdPage,
      },
      //faqs
      {
        path: "/faqs/create",
        name: "FaqsCreatePage",
        component: FaqsCreatePage,
      },
      {
        path: "/faqs/:faqId",
        name: "FaqsIdPage",
        component: FaqsIdPage,
      },
      {
        path: "/faqs",
        name: "FaqsIndexPage",
        component: FaqsIndexPage,
      },
      //upload salaries
      {
        path: "/upload-salaries",
        name: "SalariesUploadPage",
        component: SalariesUploadPage,
      },
      {
        path: "/salaries/:userId",
        name: "SalariesIndexPage",
        component: SalariesIndexPage,
      },
      //letters
      {
        path: "/letters/:userId",
        name: "LettersIndexPage",
        component: LettersIndexPage,
      },
      {
        path: "/letters/create/:userId/:letterTypeId",
        name: "LettersCreatePage",
        component: LettersCreatePage,
      },
      {
        path: "/letters/:letterId/edit",
        name: "LettersIdPage",
        component: LettersIdPage,
      },
    ],
  },
  // End AuthLayout

  // UnAuthLayout
  {
    path: "/unauth",
    name: "UnAuthLayout",
    component: UnAuthLayout,
    children: [
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
      },
    ],
  },
  //End UnAuthLayout
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name === "LoginPage" ||
    to.name === "RegisterPage" ||
    to.name === "ForgotPasswordPage"
  ) {
    if (store.getters["auth/authenticated"]) {
      return next({
        name: "HomePage",
      });
    }
    next();
  } else if (!store.getters["auth/authenticated"]) {
    return next({
      name: "LoginPage",
    });
  } else if (to.name === null) {
    return next({
      name: "HomePage",
    });
  }
  next();
});

export default router;
