import Vue from "vue";
import moment from "moment";

Vue.filter("hhmm", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value, ["HH:mm:ss"]).format("hh:mm A");
});

Vue.filter("ddmmyyyy", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("DD-MM-YYYY");
});