<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ml-4">
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add a Company
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Company Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email"
                outlined
                label="Email *"
                prepend-inner-icon="mdi-email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.contact_person"
                outlined
                label="Contact Person *"
                prepend-inner-icon="mdi-card-account-phone"
                :error-messages="errors.contact_person"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.phone"
                outlined
                label="Phone *"
                prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.address"
                outlined
                label="Address *"
                prepend-inner-icon="mdi-map-marker-multiple"
                :error-messages="errors.address"
              ></v-text-field>
            </v-col>
            <!-- <v-col sm="4">
              <v-text-field
                v-model="form.city"
                outlined
                label="City *"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.city"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.state"
                outlined
                label="State *"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.state"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.pincode"
                outlined
                label="Pincode *"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.pincode"
              ></v-text-field>
            </v-col> -->
            <!-- <v-col sm="4">
              <v-switch
                v-model="form.is_mail_sent"
                inset
                :label="`Do you want to send registration Email? ${
                  form.is_mail_sent ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col> -->
            <v-col sm="4">
              <v-switch
                v-model="form.is_active"
                inset
                :label="`Is Active: ${
                  form.is_active.toString() == 'true' ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>

          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save">
            Save Company
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "CompanyCreatePage",
  data() {
    return {
      form: {
        name: "",
        is_active: true,
      },
      isLoading: false,
    };
  },

  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/companies", this.form);
        this.isLoading = false;
        this.$router.push("/companies");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
  },
};
</script>
