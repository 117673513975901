<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" class="mt-4 ml-4">
        <v-col>
          <v-btn class="mx-2 mb-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          <span class="text-h5 font-weight-bold" v-text="`Pjp Reference`"></span>
          <v-btn class="mx-2 mb-2" fab x-small color="primary" :to="`/pjp-references/create`">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <v-row>
                <v-col sm="3">
                  <v-autocomplete ref="employee" v-model="user_id" :items="employees" label="Employee" @input="getData"
                    prepend-inner-icon="mdi-account"></v-autocomplete>
                </v-col>
                <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="pjpReferences.length != 0"><v-spacer></v-spacer><v-btn class="primary mx-4"
                v-if="ExportPjps.length == 0" @click="addToExcelData()"><span v-if="isExcelloading"> Generating Excel...
                </span>
                <span v-else>Generate Excel</span></v-btn>
              <v-btn v-else class="success mx-4"><download-excel :title="title" :data="ExportPjps" :fields="excel_fields"
                  worksheet="Pjps" name="Pjps.xls">Download Excel
                </download-excel></v-btn></v-card-title>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Supervisor Name</th>
                    <th class="text-left">location</th>
                    <th class="text-left">Day</th>
                    <th class="text-left">Week</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody v-if="pjpReferences.length != 0">
                  <tr v-for="(pjpReference, i) in pjpReferences" :key="`pjpReference${i}`">
                    <td style="min-width: 85px">
                      {{ i + 1 }}
                    </td>
                    <td>{{ pjpReference.user.name || "" }}</td>
                    <td>{{ pjpReference.location || "" }}</td>
                    <td>{{ pjpReference.day || "" }}</td>
                    <td>{{ pjpReference.week || "" }}</td>
                    <td class="text-right">
                      <v-btn class="mx-2" fab x-small color="primary" :to="`/pjp-references/${pjpReference.id}`">
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                      <v-btn class="mx-2" fab x-small color="error" @click="Delete_this(pjpReference.id)">
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "pjpReferencesPage",
  data() {
    return {
      user_id: "",
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      date_menu: false,
      isLoading: false,
      isExcelloading: false,
      ExportPjps: [],
      Export_pjpReferences: [],
      pjpReferences: [],
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Email: "email",
        Phone: "phone",
        Supervisor: "supervisor",
        "Is Active": "status",
      },
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.supervisor_id = this.user.id;
    }
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`pjp_references/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (employee.supervisor_id == this.user.id) {
            this.employees.push({
              id: employee.id,
              text: employee.name,
              value: employee.id,
            });
          }
        } else {
          this.employees.push({
            id: employee.id,
            text: employee.name,
            value: employee.id,
          });
        }
      });
    },
    async getData() {
      this.isLoading = true;
      this.user_id = this.user_id ? this.user_id : "";
      let pjpReferences = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&userId=" +
        this.user_id;
      pjpReferences = await axios.get(`pjp_references?${query}`);
      this.pjpReferences = pjpReferences.data.data;
      this.count = pjpReferences.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },

    async addToExcelData() {
      this.isExcelLoading = true;
      this.title = "Company Lists";
      let Export_pjpReferences = [];
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.form.active +
        "&roleId=" +
        this.form.role_id;
      Export_pjpReferences = await axios.get(`pjpReferences?${query}`);
      this.Export_pjpReferences = Export_pjpReferences.data.data;

      this.Export_pjpReferences.forEach((user) => {
        this.ExportPjps.push({
          sr_no: user.sr_no,
          name: user.name ? user.name.toUpperCase() : "",
          email: user.email ? user.email.toUpperCase() : "",
          phone: user.phone ? user.phone : "",

          supervisor: user.supervisor_id
            ? user.supervisor.name.toUpperCase()
            : "",
          status: user.active == 1 ? "YES" : "NO",
        });
      });
      this.isExcelLoading = false;
    },
    async Delete_this(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.delete(`pjp_references/${id}`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
