<template>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col md="12">
            <v-card elevation="6">
              <v-card-text>
                <v-row>
                <v-btn
                  class="mt-2 mx-2 text-caption"
                  color="primary"
                  :to="`/faqs/create`"
                >
                  <span> add faq <v-icon dark> mdi-plus </v-icon></span>
                </v-btn>
              </v-row>
                <v-row>
                  <v-col md="6">
                    <v-text-field
                      v-model="search_keyword"
                      label="Search ... "
                      prepend-inner-icon="mdi-magnify"
                      @keydown.enter="getData"
                      @change="page = 1"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-row v-else>
          <v-col md="12">
            <v-card elevation="4">
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-start">Sr No</th>
                      <th class="text-start">Description</th>
                      <th class="text-start">Word Document</th>
                      <th class="text-start">Pdf document</th>
                      <th class="text-right">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="faqs.length != 0">
                    <tr v-for="(faq, i) in faqs" :key="`faq${i}`">
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>{{ faq.description || "" }}</td>
                      <td v-if="faq.word_path != null">   <a :href="mediaUrl + faq.word_path">Download Word Document</a> </td>
                      <td v-else>No Documents </td>
                      <td v-if="faq.pdf_path != null">   <a :href="mediaUrl + faq.pdf_path" target="_blank">View Pdf Document</a> </td>
                      <td v-else>No Documents </td>
                      <td class="text-right">
                        <v-btn
                          class="mx-2"
                          fab
                          x-small
                          color="primary"
                          :to="`/faqs/${faq.id}`"
                        >
                          <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="8" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "faqsPage",
    data() {
      return {
        filter: {
        },
        page: 1,
        serialNoStarting: 1,
        paginationLength: 0,
        count: 0,
        search_keyword: "",
        faq_id: "",
        isLoading: false,
        faqs: [],
      };
    },
    watch: {
      page: "getData",
    },
    mounted() {
      this.getData();
    },
    methods: {
      async getData() {
        this.isLoading = true;
        this.search_keyword = this.search_keyword ? this.search_keyword : "";
        let faqs = [];
        let query =
          "page=" +
          this.page +
          "&rowsPerPage=" +
          this.rowsPerPage +
          "&search_keyword=" +
          this.search_keyword;
        faqs = await axios.get(`/faqs?${query}`);
        this.faqs = faqs.data.data;
        console.log(this.faqs);
        this.count = faqs.data.count;
        this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
        this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        this.isLoading = false;
      },
    },
  };
  </script>
  