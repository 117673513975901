<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" class="mt-4 ml-4">
        <v-col>
          <v-btn class="mx-2 mb-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          <span class="text-h5 font-weight-bold" v-text="`Expenses Statement`"></span>
          <v-btn class="mx-2 mb-2" fab x-small color="primary" :to="`/pjps/${$route.params.pjpId}/pjp-expenses/create`">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <v-row>
                <v-col md="3">
                  <v-text-field v-model="search_keyword" label="Search ... " prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData" @change="page = 1"></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="expense" v-model="expense_type_id" :items="expenseTypes" label="Expense Type"
                    @input="getData" prepend-icon="mdi-cash"></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="trvel" v-model="travelModeId" :items="travelModes" label="Travel Mode"
                    @input="getData" prepend-icon="mdi-train-car"></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="3">
                  <v-autocomplete ref="employee" v-model="user_id" :items="employees" label="Employee" @input="getData"
                    prepend-icon="mdi-account"></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="supervisor" v-model="supervisor_id" :items="supervisors" label="Supervisor"
                    @input="getData" prepend-icon="mdi-account"></v-autocomplete>
                </v-col>

                <v-col sm="3">
                  <v-autocomplete ref="approve" v-model="isApproved" :items="statuses" label="Approve Status"
                    @input="getData" prepend-icon="mdi-toggle-switch"></v-autocomplete>
                </v-col>
                <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="pjpExpenses.length != 0"><v-spacer></v-spacer><v-btn class="primary mx-4"
                v-if="ExportPjps.length == 0" @click="addToExcelData()"><span v-if="isExcelloading"> Generating Excel...
                </span>
                <span v-else>Generate Excel</span></v-btn>
              <v-btn v-else class="success mx-4"><download-excel :title="title" :data="ExportPjps" :fields="excel_fields"
                  worksheet="Pjps" name="Pjps.xls">Download Excel
                </download-excel></v-btn></v-card-title>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Employee Name</th>
                    <th class="text-left">Travel Mode</th>
                    <th class="text-left">Expense type Mode</th>
                    <th class="text-left">From</th>
                    <th class="text-left">To</th>
                    <th class="text-left">Amount</th>
                    <th class="text-left">Supervisor</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody v-if="pjpExpenses.length != 0">
                  <tr v-for="(pjpExpense, i) in pjpExpenses" :key="`pjpExpense${i}`">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="pjpExpense.is_approved == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small dark color="green">
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Visited</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="pjpExpense.is_approved == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small dark color="red">
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Not Visited</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td>{{ pjpExpense.user.name || "" }}</td>
                    <td>{{ pjpExpense.travel_mode ? pjpExpense.travel_mode.code : "" }}</td>
                    <td>{{ pjpExpense.expense_type.code || "" }}</td>
                    <td>{{ pjpExpense.from || "" }}</td>
                    <td>{{ pjpExpense.to || "" }}</td>
                    <td>{{ pjpExpense.amount || "" }}</td>
                    <td>{{ pjpExpense.supervisor.name || "" }}</td>

                    <td class="text-right">
                      <v-btn class="mx-2" fab x-small color="primary"
                        :to="`/pjps/${$route.params.pjpId}/pjp-expenses/${pjpExpense.id}`">
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                      <v-btn class="mx-2" fab x-small color="error" @click="Delete_this(pjpExpense.id)">
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "pjpExpensesPage",
  data() {
    return {
      isApproved: "",
      supervisor_id: "",
      travelModeId: "",
      expense_type_id: "",
      user_id: "",
      pjp_id: "",
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      statuses: [
        {
          id: 0,
          text: "Select Approve Status",
          value: "",
        },
        { id: "APPROVED", text: "APPROVED", value: "1" },
        { id: "NOT APPROVED", text: "NOT APPROVED", value: "0" },
      ],

      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      allowanceTypes: [
        {
          id: 0,
          text: "Select Allowance Type",
          value: "",
        },
      ],
      expenseTypes: [
        {
          id: 0,
          text: "Select Expense Type",
          value: "",
        },
      ],
      travelModes: [
        {
          id: 0,
          text: "Select Trevel Mode",
          value: "",
        },
      ],
      date_menu: false,
      isLoading: false,
      isExcelloading: false,
      ExportPjps: [],
      Export_pjpExpenses: [],
      pjpExpenses: [],
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Email: "email",
        Phone: "phone",
        Supervisor: "supervisor",
        "Is Active": "status",
      },
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    if (this.$route.params.pjpId) {
      this.pjp_id = this.$route.params.pjpId;
    }
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.supervisor_id = this.user.id;
    }
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`pjp_expenses/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (employee.supervisor_id == this.user.id) {
            this.employees.push({
              id: employee.id,
              text: employee.name,
              value: employee.id,
            });
          }
        } else {
          this.employees.push({
            id: employee.id,
            text: employee.name,
            value: employee.id,
          });
        }
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        this.supervisors.push({
          id: supervisor.id,
          text: supervisor.name,
          value: supervisor.id,
        });
      });
      // Trvel Mode
      masters.travel_modes.forEach((travelMode) => {
        this.travelModes.push({
          id: travelMode.id,
          text: travelMode.code,
          value: travelMode.id,
        });
      });
      // Trvel Mode
      masters.expense_types.forEach((expenseType) => {
        this.expenseTypes.push({
          id: expenseType.id,
          text: expenseType.code,
          value: expenseType.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.user_id = this.user_id ? this.user_id : "";
      this.supervisor_id = this.supervisor_id ? this.supervisor_id : "";
      this.trevel_mode_id = this.trevel_mode_id ? this.trevel_mode_id : "";
      this.expense_type_id = this.expense_type_id ? this.expense_type_id : "";
      this.isApproved = this.isApproved ? this.isApproved : "";
      let pjpExpenses = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&is_approved=" +
        this.isApproved +
        "&travelModeId=" +
        this.travelModeId +
        "&expenseTypeId=" +
        this.expense_type_id +
        "&userId=" +
        this.user_id +
        "&pjpId=" +
        this.pjp_id +
        "&supervisorId=" +
        this.supervisor_id;
      pjpExpenses = await axios.get(`pjp_expenses?${query}`);
      this.pjpExpenses = pjpExpenses.data.data;
      this.count = pjpExpenses.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },

    async addToExcelData() {
      this.isExcelLoading = true;
      this.title = "Company Lists";
      let Export_pjpExpenses = [];
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.form.active +
        "&roleId=" +
        this.form.role_id;
      Export_pjpExpenses = await axios.get(`pjpExpenses?${query}`);
      this.Export_pjpExpenses = Export_pjpExpenses.data.data;

      this.Export_pjpExpenses.forEach((user) => {
        this.ExportPjps.push({
          sr_no: user.sr_no,
          name: user.name ? user.name.toUpperCase() : "",
          email: user.email ? user.email.toUpperCase() : "",
          phone: user.phone ? user.phone : "",

          supervisor: user.supervisor_id
            ? user.supervisor.name.toUpperCase()
            : "",
          status: user.active == 1 ? "YES" : "NO",
        });
      });
      this.isExcelLoading = false;
    },
    async Delete_this(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.delete(`pjp_expenses/${id}`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
