<template>
    <v-main>
        <v-container fluid>
            <v-row justify="center" class="mt-4 ml-4">
                <v-col>
                    <span class="text-h5 font-weight-bold" v-text="'Copy References'"></span>
                </v-col>
            </v-row>

            <v-row>
                <v-col md="12">
                    <v-card elevation="6">
                        <v-card-text>
                            <v-row>
                                <v-col sm="3">
                                    <v-autocomplete ref="employee" v-model="user_id" outlined :items="employees"
                                        label="Employee" prepend-inner-icon="mdi-account"></v-autocomplete>
                                </v-col>
                                <v-col sm="3">
                                    <v-menu v-model="month_menu" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field outlined v-model="filter.month_year" label="From - Year - Month"
                                                prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                                clearable :error-messages="errors.month"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.month_year" type="month"
                                            @change="(month_menu = false)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn @click="confirmCopy" v-if="is_copied == false" class="primary mx-4">
                                    <span v-if="isCopy"> Copying...
                                    </span>
                                    <span v-else>Copy</span></v-btn>
                                <v-btn v-if="is_copied" class="green mx-4">
                                    <span style="color: white;"> Pjps Copied
                                    </span></v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model="confirmDialog" max-width="500">
                <v-card>
                    <v-card-title class="headline">Confirmation</v-card-title>
                    <v-card-text>
                        Are you sure you want to copy the data?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="copy" :disabled="isCopy">Yes</v-btn>
                        <v-btn color="error" @click="confirmDialog = false">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialog" max-width="500">
                <v-card>
                    <v-card-title style="color: red;">Copied Data</v-card-title>
                    <v-card-text>Data is Already Copied</v-card-text>
                    <v-card-actions>
                        <v-btn color="green" @click="dialog = false">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-container>
    </v-main>
</template>
  
<script>
import axios from "axios";
export default {
    name: "pjpsPage",
    data() {
        return {
            filter: {
            },
            user_id: "",
            isCopy: false,
            month_menu: false,
            dialog: false,
            to_month_menu: false,
            confirmDialog: false,
            is_copied: false,
            employees: []
        };
    },
    watch: {
        page: "getData",
    },
    mounted() {
        if (this.user.roles[0].name == "SUPERVISOR") {
            this.supervisor_id = this.user.id;
        }
        this.getMasters();
    },
    methods: {
        async getMasters() {
            let masters = await axios.get(`pjps/masters`);
            masters = masters.data;
            // Employees
            masters.employees.forEach((employee) => {
                if (this.user.roles[0].name == "SUPERVISOR") {
                    if (employee.supervisor_id == this.user.id) {
                        this.employees.push({
                            id: employee.id,
                            text: employee.name,
                            value: employee.id,
                        });
                    }
                } else {
                    this.employees.push({
                        id: employee.id,
                        text: employee.name,
                        value: employee.id,
                    });
                }
            });
        },
        showDialog() {
            this.dialog = true;
        },
        confirmCopy() {
            this.confirmDialog = true;
        },
        async copy() {
            this.isCopy = true;
            if (this.filter.month_year) {
                this.filter.year = this.filter.month_year.split("-")[0];
                this.filter.month = this.filter.month_year.split("-")[1];
            }
            let pjps = [];
            let query =
                "year=" +
                this.filter.year +
                "&month=" +
                this.filter.month +
                "&userId=" +
                this.user_id;
            pjps = await axios.post(`pjps/copy_pjps?${query}`);
            console.log(pjps);
            if (pjps.data.data.status == 'error') {
                console.log(pjps);
                this.confirmDialog = false;
                this.dialog = true;
                this.isCopy = false;
                this.is_copied = false;
            } else {
                this.pjps = pjps.data.data;
                this.isCopy = false;
                this.is_copied = true;
                this.confirmDialog = false;
            }
        },
    },
};
</script>
  