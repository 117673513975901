<template>
  <!-- partial -->
  <v-main>
    <v-row style="padding: 20px; background: #f1f2f6;">
      <div class="page-content">
        <v-progress-circular v-if="isLoading" indeterminate color="#0b4bff"></v-progress-circular>

        <div v-else class="container-fluid">
          <div class="row heading-bg">
            <div class="col-md-12 grid-margin stretch-card" style="padding-top: 20px;">
              <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h5 style="color: #0b4bff;">DashBoard</h5>
              </div>
              <SaCounts></SaCounts>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="chart_data">
                <v-col sm="8" style="position: relative;">
                  <div id="chart1">
                  </div>
                  <div class="chart-radio">
                    <input type="radio" id="daily" value="daily" v-model="selectedDateRange"
                      @click="changeChart('daily')">
                    <label for="daily">Daily</label>

                    <input type="radio" id="weekly" value="weekly" v-model="selectedDateRange"
                      @click="changeChart('weekly')">
                    <label for="weekly">Weekly</label>

                    <input type="radio" id="monthly" value="monthly" v-model="selectedDateRange"
                      @click="changeChart('monthly')">
                    <label for="monthly">Monthly</label>
                  </div>
                </v-col>
                <v-col sm="4">
                  <div id="chart">
                  </div>
                </v-col>
              </div>
            </div>

            <div class="col-md-12 grid-margin stretch-card" style="padding: 24px;">
              <v-card elevation="6" style="box-shadow: none !important;">
                <v-card-title>
                  <v-col>
                    <h2 style="color: #9699af; text-align: end; font-weight: 500; font-size: 21px;">Attendance OverView
                    </h2>
                  </v-col>
                  <v-col md="2">
                    <v-pagination v-model="page" :length="paginationLength" color="#3354f4"
                      style="justify-content: start !important;" circle>
                    </v-pagination>
                  </v-col>
                  <v-col sm="2">
                    <v-autocomplete ref="supervisor" v-model="designationId" :items="designations" label="Designations"
                      style="font-weight: 300;" @input="getAttendance" prepend-icon="mdi-account"
                      clearable></v-autocomplete>
                  </v-col>
                  <v-col sm="2" style="max-width: 12%;">
                    <v-text-field class="search-field2" v-model="current_date"
                      prepend-inner-icon="mdi-calendar-month-outline" clearable flat solo hide-details filled
                      disabled></v-text-field>
                  </v-col>
                  <v-col sm="4">
                    <v-text-field class="search-field" v-model="search_keyword" flat solo hide-details
                      prepend-inner-icon="mdi-magnify" label="Search" @keydown.enter="getAttendance"
                      @change="page = 1"></v-text-field>
                  </v-col>
                </v-card-title>
                <v-simple-table fixed-header style="padding: 16px;">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Sr No</th>
                        <th class="text-left">Employee Code</th>
                        <th class="text-left">Employee Name</th>
                        <th class="text-left">Date</th>
                        <th class="text-left">Login Time</th>
                        <th class="text-left">Logout Time</th>
                        <th class="text-left">Session Type</th>
                        <th class="text-left">Supervisor</th>
                        <th class="text-right" v-if="roleName == 'SUPER ADMIN'">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="userAttendances.length != 0">
                      <tr v-for="(user, i) in userAttendances" :key="`user${i}`">
                        <td style="min-width: 85px">
                          <v-tooltip bottom v-if="user.active == 1">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small dark color="green">
                                mdi-circle
                              </v-icon>
                            </template>
                            <span>User is Active</span>
                          </v-tooltip>
                          <v-tooltip bottom v-if="user.active == 0">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small dark color="red">
                                mdi-circle
                              </v-icon>
                            </template>
                            <span>User is InActive</span>
                          </v-tooltip>
                          &nbsp;
                          {{ i + 1 }}
                        </td>
                        <td>{{ user.user.employee_code || "" }}</td>
                        <td>{{ user.user.name || "" }}</td>
                        <td>{{ user.date || "" }}</td>
                        <td>{{ user.login_time | hhmm }}</td>
                        <td>{{ user.logout_time | hhmm }}</td>
                        <td>{{ user.session_type.code || "" }}</td>
                        <td>{{ user.user.supervisor ? user.user.supervisor.name : '' }}</td>

                        <td class="text-right" v-if="roleName == 'SUPER ADMIN'">
                          <v-btn class="mx-2" fab x-small color="primary" :to="`/user-attendances/${user.id}`">
                            <v-icon dark> mdi-pencil </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="8" class="text-center">No data found</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </div>

          </div>
        </div>

      </div>
    </v-row>
  </v-main>
  <!-- partial -->
</template>
<script src="https://cdn.jsdelivr.net/npm/apexcharts"></script>
<script>
import SaCounts from "./employeecount.vue";
import ApexCharts from 'apexcharts'
import axios from "axios";
import { ref, onMounted } from 'vue';

export default {
  name: "HomePage",
  data() {
    return {
      isLoading: false,
      roleId: "",
      counts:{},
      attendace_count:[],
      startDate:'',
      endDate:'',
      userAttendances:[],
      current_date:'',
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      designations:[ {
          id: 0,
          text: "Select Designation",
          value: "",
        },],
      designationId:'',
      selectedDateRange: 'daily',
      startDate:'',
      endDate:''
    };
  },
  components: {
    SaCounts,
  },
  watch: {
    page: "getAttendance",
  },
  setup() {
  const chart = ref(null);

  const generateChartData = async (interval, startDate, endDate) => {
    let labels = [];
    let data = [];

    if (interval === 'daily') {
      let currentDate = new Date(startDate);
      const isoStartDate = currentDate.toISOString().split('T')[0];
      const isoEndDate = endDate.toISOString().split('T')[0];
      try {
        let response = await axios.get(`/admin_dashboard?end_date=${isoEndDate}`);
        data = response.data.data; // or response.data.someProperty
        console.log(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      while (currentDate <= endDate) {
        const formattedDate = currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' });
      labels.push(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
      }
    } else if (interval === 'weekly') {
      labels = ['Week 1', 'Week 2', 'Week 3', 'Current Week'];
      try {
        let response = await axios.get(`/admin_dashboard?week_count=true`);
        data = response.data.data; // or response.data.someProperty
        console.log(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    
    } else if (interval === 'monthly') {
      labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      try {
        let response = await axios.get(`/admin_dashboard?months_count=true`);
        data = response.data.data; // or response.data.someProperty
        console.log(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    return { labels, data };
  };
  const changeChart = async (interval) => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
    const { labels, data } = await generateChartData(interval, startDate , endDate);
    chart.value.updateOptions({
      xaxis: {
        categories: labels,
      },
    });

    chart.value.updateSeries([{
      name: 'Data',
      data: data,
    }]);
  };
  onMounted(async () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
    const { labels, data } = await generateChartData('daily', startDate, endDate);
    const options = {
      series: [{
        name: 'Count',
        data: data,
      }],
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: labels,
      },
      title: {
          text: 'Users Attendance Chart',
          align: 'left',
          style: {
            fontSize: "21px",
            color: '#6c718e',
            fontFamily: 'Afacad, sans-serif',
            fontWeight: '500',
          }
        },
        fill: {
          type: 'gradient',
        },
    colors: ['#3354f4'], // Set the color you want
    };

    chart.value = new ApexCharts(document.querySelector('#chart1'), options);
    chart.value.render();
  });

  return {
    chart,
    changeChart,
  };
},


  async mounted() {
    // this.isLoading = true;
     await this.getData();
     this.getMasters();
     this.getAttendance();
    // this.isLoading = false;
  },

  methods: {
    async getMasters() {
      let masters = await axios.get(`users/masters`);
      masters = masters.data;
     masters.designations.forEach((designation) => {
        this.designations.push({
          id: designation.id,
          text: designation.description,
          value: designation.id,
        });
      });
    },
    async getAttendance(){
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, '0');
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = currentDate.getFullYear();
      this.current_date = `${year}-${month}-${day}`;
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        10 +
        "&search_keyword=" +
        this.search_keyword +
        "&designationId=" +
        this.designationId +
        "&date="+
        this.current_date;
      let userAttendances = [];
        userAttendances = await axios.get(`user_attendances?${query}`);
      this.userAttendances = userAttendances.data.data;
      this.count = userAttendances.data.count;
      this.serialNoStarting = (this.page - 1) * 10;
      this.paginationLength = Math.ceil(this.count / 10);
    },
    async getData() {
      let response = await axios.get(`/admin_dashboard`);
      this.counts = response.data;
      this.attendace_count = response.data.attendance_count;
      const designationData = response.data.designationAttendanceCounts;
      // Extracting labels and counts from the response
      const labels = designationData.map(item => item.designation_name);
      const counts = designationData.map(item => item.Percentage_attendance);

      var options = {
          series: [{
          name: 'count',
          data: counts
         }],
          chart: {
          height: 350,
          type: 'bar',
          
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + '%';
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: labels,
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                colors: '#e6eef5',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        title: {
          text: 'Weekly Attendance',
          align: 'left',
          style: {
            fontSize: "21px",
            color: '#6c718e',
            fontFamily: 'Afacad, sans-serif',
            fontWeight: '500',
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "%";
            }
          }
        
        },
        colors: ['#3354f4'] 
        };
      var chart = new ApexCharts(document.querySelector("#chart"), options);
      chart.render();
     
    },
    
  },

};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Afacad:wght@400;500&display=swap');

.chart_data {
  display: flex;
}

#chart,
#chart1 {
  background: white;
}

.card-button2 {
  padding: 7px 15px !important;
  background: #3354f4;
  color: white;
  border-radius: 10px;
  font-size: 14px;
}

.page-content {
  width: 100%;
}

.search-field {
  border: 1px solid #dfe2e5;
  border-radius: 10px;
}

.search-field2 {
  border: 1px solid #dfe2e5;
  border-radius: 10px;
}

.text-left {
  color: #c1c1c5 !important;
}

.chart-radio {
  position: absolute;
  top: 20px;
  right: 32px;
}

.chart-radio input {
  margin-left: 10px;
  margin-right: 3px;
}

.chart-radio label {
  color: #6c718e !important;
  font-family: 'Afacad', sans-serif;
}
</style>
