<template>
    <v-main>
        <v-container fluid>
            <v-card elevation="6">
                <v-card-title class="mt-4 ml-4">
                    <v-icon dark>mdi-arrow-left</v-icon>
                    Add a geofence
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col sm="4">
                            <v-text-field v-model="formData.identifier" label="Identifier" required></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model.number="formData.radius" label="Radius in meter" type="number"
                                required></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model.number="formData.latitude" label="Latitude" type="number"
                                required></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model.number="formData.longitude" label="Longitude" type="number"
                                required></v-text-field>
                        </v-col>
                        <!-- <v-col sm="4">
                            <v-checkbox v-model="formData.notifyOnEntry" label="Notify on Entry"></v-checkbox>
                        </v-col>
                        <v-col sm="4">
                            <v-checkbox v-model="formData.notifyOnExit" label="Notify on Exit"></v-checkbox>
                        </v-col> -->


                        <v-col sm="4" style="display: flex; justify-content: center; align-items: end;">
                            <v-btn @click="submitData" color="primary">Submit</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">ID</th>
                            <th class="text-left">Identifier</th>
                            <th class="text-left">Latitude</th>
                            <th class="text-left">Longitude</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in geofenceData" :key="item.id">
                            <td>{{ item.id }}</td>
                            <td>{{ item.identifier }}</td>
                            <td>{{ item.latitude }}</td>
                            <td>{{ item.longitude }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-container>
        <v-dialog v-model="dialog" max-width="400">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Success
                </v-card-title>
                <v-card-text>
                    Geofence added successfully!
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeDialog">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>
</template>
  
<script>
import axios from 'axios';

export default {
    name: 'GeoFence',
    data() {
        return {
            formData: {
                identifier: '',
                radius: 0,
                latitude: 0,
                longitude: 0,
                notifyOnEntry: false,
                notifyOnExit: false,
                notifyOnDwell: false,
                loiteringDelay: 0,
                extras: {
                    'A': 'A'
                },
                vertices: [],
            },
            extrasKey: '',
            extrasValue: '',
            vertexLat: 0,
            vertexLong: 0,
            dialog: false,
            geofenceData: [],
        };
    },
    async created() {
        try {
            const response = await axios.get("geofences");
            this.geofenceData = response.data.data;
        } catch (error) {
            console.error("Error fetching geofence data:", error);
        }
    },
    methods: {

        submitData() {

            axios
                .post('geofences', this.formData)
                .then((response) => {
                    if (response.data.success) {
                        this.dialog = true;
                    }
                    console.log('Data submitted successfully', response.data);
                })
                .catch((error) => {
                    console.error('Error submitting data', error);
                });
        },
        closeDialog() {
            this.dialog = false;
            window.location.reload();

        },
    },
};
</script>
  
<style>
/* Add any custom styles here */
</style>
  