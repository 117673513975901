<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" class="mt-4 ml-4">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Companies'"></span>
          <v-btn
            class="mx-2 mb-2"
            fab
            x-small
            color="primary"
            to="/companies/create"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData"
                    @change="page = 1"
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="status"
                    v-model="form.is_active"
                    :items="statuses"
                    label="Status"
                    @input="getData"
                    prepend-icon="mdi-toggle-switch"
                  ></v-autocomplete>
                </v-col>
                <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="companies.length != 0"
              ><v-spacer></v-spacer
              ><v-btn
                class="primary mx-4"
                v-if="ExportCompanies.length == 0"
                @click="addToExcelData()"
                ><span v-if="isExcelloading"> Generating Excel... </span>
                <span v-else>Generate Excel</span></v-btn
              >
              <v-btn v-else class="success mx-4"
                ><download-excel
                  :title="title"
                  :data="ExportCompanies"
                  :fields="excel_fields"
                  worksheet="Companies"
                  name="Companies.xls"
                  >Download Excel
                </download-excel></v-btn
              ></v-card-title
            >
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Email</th>
                    <th class="text-left">Contact Person</th>
                    <th class="text-left">Phone</th>
                    <th class="text-left">Address</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody v-if="companies.length != 0">
                  <tr v-for="(company, i) in companies" :key="company.name">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="company.is_active == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="green"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Company is Active</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="company.is_active == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="red"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Company is InActive</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td>{{ company.name || "" }}</td>
                    <td>{{ company.email || "" }}</td>
                    <td>{{ company.contact_person || "" }}</td>
                    <td>{{ company.phone || "" }}</td>
                    <td>
                      {{ company.address || "" }}
                    </td>

                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/companies/${company.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                      
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="7" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <!-- Mail Sent Success Dialog Box -->
      <!-- <v-dialog v-model="is_Mail_Sent" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-cloud-upload-outline
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>
                        The registration mail has been sent successfully. Now
                        you can ask them to check their mail.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Mail_Sent = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog> -->
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "companiesPage",
  data() {
    return {
      form: {
        is_active: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      companies: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      isLoading: false,
      isExcelloading: false,
      ExportCompanies: [],
      Export_companies: [],
      // is_Mail_Sent: false,
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Email: "email",
        "Contact Person": "contact_person",
        Phone: "phone",
        Address: "address",
        "Is Active": "status",
      },
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let companies = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.form.is_active;
      companies = await axios.get(`companies?${query}`);
      this.companies = companies.data.data;
      this.count = companies.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },

    async addToExcelData() {
      this.isExcelLoading = true;
      this.title = "Company Lists";
      let Export_companies = [];
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.form.is_active;
      Export_companies = await axios.get(`companies?${query}`);
      this.Export_companies = Export_companies.data.data;

      this.Export_companies.forEach((company) => {
        this.ExportCompanies.push({
          sr_no: company.sr_no,
          name: company.name ? company.name.toUpperCase() : "",
          email: company.email ? company.email.toUpperCase() : "",
          phone: company.phone ? company.phone : "",
          contact_person: company.contact_person
            ? company.contact_person.toUpperCase()
            : "",
          address: company.address ? company.address.toUpperCase() : "",
          status: company.is_active == 1 ? "YES" : "NO",
        });
      });
      this.isExcelLoading = false;
    },
    // async SendMail(id, type = "SCHOOL") {
    //   this.sending_mail = true;
    //   let is_mail_sent = "";
    //   if (type == "SCHOOL") {
    //     let query = "school_id=" + id;
    //     is_mail_sent = await axios.get(`/companies/send_mail?${query}`);
    //   } else {
    //     let query = "user_id=" + id;
    //     is_mail_sent = await axios.get(`/users/send_mail?${query}`);
    //   }
    //   if (is_mail_sent) {
    //     this.sending_mail = false;
    //     this.is_Mail_Sent = true;
    //     this.getData();
    //   }
    // },
  },
};
</script>
