<template>
  <v-main>
    <v-container fluid>
      <v-card>
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          Create {{ $route.query.type }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <vue-editor v-model="form.letter" />
              <div v-if="errors && errors.letter" class="error-message">
                <span style="color: red"
                  >Letter Field Required*</span
                >
              </div>
            </v-col>

            <v-col v-if="$route.query.type == 'offer letter'" cols="4">
              <label for="">Start Date</label>
              <v-menu
                v-model="start_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.start_date"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.start_date = null"
                    :error-messages="errors.date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.start_date"
                  @change="start_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="$route.query.type == 'offer letter'" cols="4">
              <label for="">End Date</label>
              <v-menu
                v-model="end_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.end_date"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.end_date = null"
                    :error-messages="errors.date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.end_date"
                  @change="end_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="$route.query.type == 'offer letter'" cols="4">
              <label for="">STC Issue Date</label>
              <v-menu
                v-model="stc_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.stc_issue_date"
                    label="Stc Issue Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.stc_issue_date = null"
                    :error-messages="errors.date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.stc_issue_date"
                  @change="stc_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <label for="">Upload PDF</label> <br />
              <input type="file" id="file_1" name="file_1" ref="file_1" />
              
            </v-col>
            <v-col>
              <v-btn
                :loading="isLoading"
                color="primary"
                class="float-right"
                @click="save()"
              >
                save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  name: "LetterCreatePage",
  components: {
    VueEditor,
  },
  data() {
    return {
      form: {},
      letter_path: "",
      start_date_menu: false,
      end_date_menu: false,
      stc_date_menu: false,
      isLoading: false,
    };
  },
  methods: {
    async save() {
      this.isLoading = true;
      try {
        this.form.letter_type_id = this.$route.params.letterTypeId;
        this.form.user_id = this.$route.params.userId;
        let letter = await axios.post("/letters", this.form);
        this.form = letter.data.data;
        await this.handleFileUpload();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async handleFileUpload() {
      let letter_path = this.$refs.file_1.files[0];
      const id = this.form.id;
      let formData = new FormData();
      formData.append("id", id);
      formData.append("letter_path", letter_path);
      await axios
        .post("upload_letters", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  },
};
</script>
