<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ml-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add Leave
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete ref="employee" v-model="form.user_id" :items="employees" label="Employee"
                prepend-icon="mdi-account" :error-messages="errors.user_id"
                @change="getUser(form.user_id)"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="supervisor" v-model="form.supervisor_id" :items="supervisors" label="Supervisor"
                prepend-icon="mdi-account" :error-messages="errors.supervisor_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-menu v-model="leave_from_date_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.leave_from_date" label="Leave from date" prepend-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on" clearable @click:clear="form.leave_from_date = null"
                    :error-messages="errors.leave_from_date"></v-text-field>
                </template>
                <v-date-picker v-model="form.leave_from_date" @change="leave_from_date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu v-model="leave_to_date_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.leave_to_date" label="Leave to date" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" clearable @click:clear="form.leave_to_date = null"
                    :error-messages="errors.leave_to_date"></v-text-field>
                </template>
                <v-date-picker v-model="form.leave_to_date" @change="leave_to_date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="leave type" v-model="form.leave_type_id" :items="leaveTypes" label="Leave Type"
                prepend-icon="mdi-account" :error-messages="errors.leave_type_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.remarks" outlined label="Remark"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field readonly v-model="form.attendance_type" outlined label="Attendance Type"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4" v-if="form.role_id == '4'">
              <v-switch v-model="form.is_approved" inset
                :label="`Is Approved: ${form.is_approved == 1 ? 'YES' : 'NO'}`"></v-switch>
            </v-col>
            <v-col sm="4" v-if="form.role_id == '4'">
              <v-text-field readonly v-model="form.decline_reason" outlined label="Decline reason"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <!-- <v-col sm="4">
              <v-text-field readonly v-model="form.remarks" outlined label="Reason"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col> -->
            <v-col sm="4">
              <v-text-field v-model="form.note" outlined label="Note"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <input type="file" id="file" name="file" ref="file" accept="image/*" multiple />
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>
          <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save">
            Save User Leave
          </v-btn>
        </v-card-text>
        <v-card-text class="white--text red" style="font-weight: bolder; font-size: large">
          Note: {{ note }}
          <br />
          Pending PL Count : {{ checkUser.pending_pl_leave_count }}
          <br />
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "UserLeaveCreatePage",
  data() {
    return {
      form: {
        leave_from_date: "",
        leave_to_date: "",
        attendance_type: "Leave",
        is_approved: false,
        session_type_id: "5",

      },
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      leaveTypes: [
        {
          id: 0,
          text: "Select Leave Type",
          value: "",
        },
      ],

      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],

      isLoading: false,
      leave_from_date_menu: false,
      leave_to_date_menu: false,
      checkUser: {},
      note: "",
      // clnote: "",
    };
  },
  computed: {
    checkLeaveDays: function () {
      const startDate = moment(this.form.leave_from_date);
      const endDate = moment(this.form.leave_to_date);
      const daysDifference = endDate.diff(startDate, "days") + 1;
      return daysDifference;
    },

    selectedLeaveType() {
      const selectedId = this.form.leave_type_id;
      const selectedItem = this.leaveTypes.find(
        (item) => item.id === selectedId
      );
      return selectedItem ? selectedItem.text : ""; // Get the name of the selected item
    },
  },
  mounted() {
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.form.supervisor_id = this.user.id;
    }
    this.getMasters();
    this.selectedLeaveType();
  },

  methods: {
    async getMasters() {
      let masters = await axios.get(`user_attendances/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (employee.supervisor_id == this.user.id) {
            this.employees.push({
              id: employee.id,
              text: employee.name,
              value: employee.id,
            });
          }
        } else {
          this.employees.push({
            id: employee.id,
            text: employee.name,
            value: employee.id,
          });
        }
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        if (this.user.roles[0].name == "SUPERVISOR") {
          if (supervisor.id == this.user.id) {
            this.supervisors.push({
              id: supervisor.id,
              text: supervisor.name,
              value: supervisor.id,
            });
          }
        } else {
          this.supervisors.push({
            id: supervisor.id,
            text: supervisor.name,
            value: supervisor.id,
          });
        }
      });
      // Session Types Is Default Leave
      this.form.session_type_id = masters.sessionTypes.find(
        (st) => st.description == "LEAVE"
      ).id;
      // Leave Types
      masters.leaveTypes.forEach((leaveType) => {
        this.leaveTypes.push({
          id: leaveType.id,
          text: leaveType.code,
          value: leaveType.id,
        });
      });
    },
    async getUser(userId) {
      console.log(this.selectedLeaveType);
      try {
        this.isLoading = true;
        let checkUser = await axios.get(`users/${userId}`);
        this.checkUser = checkUser.data.data;
        this.isLoading = false;
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    async save() {
      try {
        this.isLoading = true;
        if (this.checkUser.doj != null) {
          const startDate = moment(this.checkUser.doj);
          const endDate = moment();
          const checkProbitionPeriod = endDate.diff(startDate, "days") + 1;

          if (checkProbitionPeriod > 1) {
            console.log('leave')
            console.log(1)
            console.log(this.form.leave_from_date)
            console.log(this.form.leave_to_date)
            let existingLeave = await axios.get(
              `/user_attendances?from_date=${this.form.leave_from_date}&to_date=${this.form.leave_to_date}&userId=${this.form.user_id}`
            );
            console.log(existingLeave);
            console.log('leave')

            this.existingLeave = existingLeave.data.data;
            if (this.existingLeave.length == 0) {
              if (
                this.selectedLeaveType == "PAID LEAVE" &&
                this.checkUser.pending_pl_leave_count > this.checkLeaveDays
              ) {
                let leave = await axios.post("/user_attendances", this.form);
                this.leave = leave.data.data;
                this.handleFileUpload();
                this.isLoading = false;
                this.$router.push("/user-leaves");
              } else if (
                this.selectedLeaveType == "CASUAL LEAVE" &&
                this.checkUser.pending_pl_leave_count > this.checkLeaveDays
              ) {
                let leave = await axios.post("/user_attendances", this.form);
                this.leave = leave.data.data;
                this.handleFileUpload();
                this.isLoading = false;
                this.$router.push("/user-leaves");
              }

              else {
                this.isLoading = false;
                this.note =
                  this.checkUser.name +
                  " are not eligible for " +
                  this.selectedLeaveType;
              }
            }
            else {
              this.isLoading = false;
              this.note =
                this.checkUser.name +
                " already applied for " +
                this.selectedLeaveType +
                " from " +
                this.form.leave_from_date +
                " to " +
                this.form.leave_to_date;
            }
          } else {
            this.isLoading = false;
            this.note =
              "During (3) three-month probation period, " +
              this.checkUser.name +
              " cannot request leave.";
          }
        } else {
          this.isLoading = false;
          this.note =
            this.checkUser.name +
            "'s date of joining not updated please update it as soon as possible.";
        }

      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      let attachment = this.$refs.file.files[0];
      const user_attendances_id = this.leave.id;
      let formData = new FormData();
      formData.append("id", user_attendances_id);
      formData.append("attachment", attachment);
      await axios
        .post("upload_user_leave_attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  },
};
</script>
