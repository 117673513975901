<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          Edit FAQ
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12">
              <v-textarea
                v-model="form.description"
                outlined
                auto-grow
                label="Description *"
                prepend-inner-icon="mdi-alert-circle"
                hint="Describe the issue which is you are facing"
                :error-messages="errors.description"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <span class="text-h5 font-weight-bold">Upload Folders</span>
            </v-col>
            <v-col sm="6">
              <b>WORD Format <span class="error--text">*</span></b>
              <br />
              <input
                type="file"
                id="file_1"
                name="file_1"
                ref="file_1"
                accept="image/*"
              />

              <template v-if="form.word_path != null">
                <br />
                <v-btn
                  x-small
                  class="mx-2"
                  :href="`${mediaUrl}${form.word_path}`"
                  target="_blank"
                  color="primary"
                  >View</v-btn
                >
                <v-btn
                  x-small
                  class="reset_image my-1"
                  color="red"
                  dark
                  @click="($refs.file_1.value = null), (form.word_path = null)"
                  >Remove</v-btn
                >
              </template>
            </v-col>
            <v-col sm="6">
              <b>PDF format <span class="error--text">*</span></b>
              <br />
              <input
                type="file"
                id="file_2"
                name="file_2"
                ref="file_2"
                accept="image/*"
              />

              <template v-if="form.pdf_path != null">
                <br />
                <v-btn
                  x-small
                  class="mx-2"
                  :href="`${mediaUrl}${form.pdf_path}`"
                  target="_blank"
                  color="primary"
                  >View</v-btn
                >
                <v-btn
                  x-small
                  class="reset_image my-2"
                  color="red"
                  dark
                  @click="($refs.file_2.value = null), (form.pdf_path = null)"
                  >Remove</v-btn
                >
              </template>
            </v-col>
            <v-col>
              <v-btn
                :loading="isLoading"
                class="float-right"
                color="primary"
                dark
                @click="update"
              >
                Update Faq
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        active: true,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
        let response  = await axios.get(`/faqs/${this.$route.params.faqId}`);
        this.form = response.data.data;
    },
    async update() {
      try {
        this.isLoading = true;
        let response = await axios.patch(`/faqs/${this.$route.params.faqId}`, this.form);
        this.form = response.data.data;
        await this.handleFileUpload();          
        this.isLoading = false;
          this.$router.back();
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;
      let faqId = this.form.id;
      let formData = new FormData();
      formData.append("id", faqId);
      let wordPath = this.$refs.file_1?.files[0];
      formData.append("word_path", wordPath);
      let pdfPath = this.$refs.file_2?.files[0];
      formData.append("pdf_path", pdfPath);
      await axios.post("upload_faqs_documents", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      this.isLoading = false;
    },
  },
};
</script>
