<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-btn text>
          <a :href="`${path}store-vyu/samples/pjp_upload_v1_0.xlsx`"
            >Download sample file</a
          >
        </v-btn>
        <v-card-title>
          <!-- <backbutton></backbutton> -->
          Upload Pjps Data
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="crude_pjps.length == 0">
              <input
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="application/ms-excel"
                multiple
                @input="handleFileUpload"
              />
            </v-col>
            <v-col v-else>
              <v-btn
                color="success"
                :loading="isProcessing"
                :disabled="isProcessing"
                @click="processFile"
              >
                Upload file</v-btn
              >

              &nbsp;
              <v-btn color="error" @click="truncatePendingDocuments">
                Delete</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <span style="color: red" v-show="isError">{{ error_msg }} </span>
          </v-row>
        </v-card-text>
      </v-card>
      <br />
      <v-card elevation="6">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Employee Name</th>
                <th class="text-left">Date</th>
                <th class="text-left">Mark In Time</th>
                <th class="text-left">Mark Out Time</th>
                <th class="text-left">Location</th>
                <th class="text-left">Latitude</th>
                <th class="text-left">Longitude</th>
                <th class="text-left">Description</th>
                <th class="text-left">Allowance Type</th>
                <th class="text-left">Reason For Not Visit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(crudpjp, at) in crude_pjps" :key="`crudpjp${at}`">
                <td>{{ crudpjp.employee_name }}</td>
                <td>{{ crudpjp.date }}</td>
                <td>{{ crudpjp.mark_in_time }}</td>
                <td>{{ crudpjp.mark_out_time }}</td>
                <td>{{ crudpjp.location }}</td>
                <td>{{ crudpjp.latitude }}</td>
                <td>{{ crudpjp.longitude }}</td>
                <td>{{ crudpjp.description }}</td>
                <td>{{ crudpjp.allowance_type }}</td>
                <td>{{ crudpjp.reason_for_not_visit }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <!-- Upload Success Dialog Box -->
      <v-dialog v-model="is_Uploaded" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-cloud-upload-outline
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>
                        The file has been uploaded successfully. Now you can
                        Preview the List & process accordingly.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Uploaded = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Process Success Dialog Box -->
      <v-dialog v-model="is_Processed" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-progress-download
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 v-if="DialogMessage != null" style="color: red">Oops!</h1>
                      <h1 v-else style="color: green">Hurray!</h1>
                      <br />
                      <h3 v-if="DialogMessage != null">
                        {{ DialogMessage }}
                      </h3>
                      <h3 v-else>
                        The file has been processed successfully. Now you can
                        see your pjps in Pjp's Tab.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Processed = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Truncate Success Dialog Box -->
      <v-dialog v-model="is_Truncated" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: red; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty-outline
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: red">Deleted!</h1>
                      <br />
                      <h3>
                        The file has been deleted successfully. Now you can
                        select a new file to upload.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Truncated = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
// import backbutton from "@/components/backbutton.vue";
export default {
  //   components: { backbutton },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Upload Pjp Excel",
  },
  data() {
    return {
      crude_pjps: [],
      isLoading: false,
      is_mail_sent: false,
      isUploading: false,
      is_Uploaded: false,
      isProcessing: false,
      is_Processed: false,
      is_Truncated: false,
      isError: false,
      error_msg: "",
      DialogMessage: "",
      path: "",
    };
  },
  mounted() {
    this.getData();
    this.path = this.mediaUrl;
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let crude_pjps = await axios.get(`/crude_pjps`);
      this.crude_pjps = crude_pjps.data.data;
      this.count = crude_pjps.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isUploading = true;
      this.isUploadingError = false;
      this.error_msg = "";
      this.pjps = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("pjps", this.pjps);
      this.changeValue = 100;
      await axios
        .post("upload_pjp", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crude_pjps = response.data.data;
          this.is_Uploaded = true;
        })
        .catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while uploading the File. Kindly check the file before uploading ...";
        });
    },
    async processFile() {
      try {
        this.isProcessing = true;
        let response = await axios.get(`process_pjp`).catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while processing the File. Kindly check the file before processing ...";
        });
        await axios.get("truncate_pjps");
        if (response.data.message != null) {
          this.DialogMessage = response.data.message;
        }
        this.crude_pjps = [];
        this.isProcessing = false;
        this.is_Processed = true;
        // alert("Processed Successfully");
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      await axios.get("truncate_pjps");
      this.is_Truncated = true;

      // alert("Truncated Successfully");
      this.crude_pjps = [];
    },
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
td {
  min-width: 130px !important;
}
</style>
